import React, { useEffect } from 'react'
import Lottie from 'react-lottie-player'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../auth'
import lottieJson from './data.json'
function Step3({ sirasi }: any) {
  const { currentUser } = useAuth()
  useEffect(() => {
    if (sirasi === 3) {
      loli();
    }
  }, [sirasi])
  function loli() {
    const myTimeout = setTimeout(myGreeting, 5000);
    function myGreeting() {
      window.location.href = `${process.env.REACT_APP_ANASAYFA}`
    }
  }
  return (
    <div className='container'>
      <div className='text-center align-items-center justify-content-center' style={{ color: '#0e7154' }}>
        <Lottie
          className='text-center align-items-center justify-content-center'
          loop
          animationData={lottieJson}
          play
          style={{ width: 250, height: 250, margin: '0 auto' }}
        />
        <h4 style={{ color: '#0e7154' }} className='text-center '> Ödeme İşleminiz Tamamlanmıştır.</h4>
        {currentUser ? (<></>) : (<>
          <span style={{ color: '#0e7154' }} className='text-center  mt-5'>Girdiğiniz telefon numarası ile sisteme kayıt olabilirsiniz. </span><br />
          <Link to={'/auth'} style={{ backgroundColor: '#0e7154' }} className='text-center text-white btn  mt-5'>Giriş Yap</Link></>)}

      </div>
    </div>
  )
}

export default Step3