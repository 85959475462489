/* eslint-disable jsx-a11y/anchor-is-valid */
import clsx from 'clsx'
import { FC, useState } from 'react'
import { useLang, setLanguage } from '../../../../_metronic/i18n/Metronici18n'
import { getLanguagesList } from '../core/_requests';
import { toAbsoluteUrl } from './../../../../_metronic/helpers/AssetHelpers';
import { useEffect } from 'react';
import { useIntl } from 'react-intl';

const languages = [
  {
    lang: 'tr',
    name: 'Türkçe',
    flag: toAbsoluteUrl('/media/flags/turkey.svg'),
  },
  {
    lang: 'en',
    name: 'English',
    flag: toAbsoluteUrl('/media/flags/united-states.svg'),
  }
]



type Props = {
  languageMenuPlacement?: 'lert-start' | 'right-end'
}

const LanguageHeader: FC<Props> = ({ languageMenuPlacement = 'left-start' }) => {
  const intl = useIntl();
  const [languages, setLanguages] = useState<any>([])

  const getLanguages = async () => {
    const languageList = await getLanguagesList()
    setLanguages(languageList)
  }

  useEffect(() => {
    getLanguages();
  }, [])
  const lang = useLang()
  const currentLanguage = languages.find((x: any) => x.lang === lang)
  return (
    <div
      className='menu-item '
      data-kt-menu-trigger='hover'
      data-kt-menu-placement={languageMenuPlacement}
      data-kt-menu-flip='bottom'
    >
      <a href='#' className='menu-link px-5'>
        <span className='menu-title position-relative'>
          <span className='fs-8   px-3 py-2 position-absolute translate-middle-y top-50 start-0'>{intl.formatMessage({ id: "MAIN.GENERAL.SELECTED.LANGUAGE" })}</span>
          <span className='fs-8 rounded bg-light px-3 py-2 position-absolute translate-middle-y top-50 end-0'>
            {currentLanguage?.name}{' '}
            <img
              className='w-15px h-15px rounded-1 ms-2'
              src={toAbsoluteUrl('/media/flags/' + currentLanguage?.flag)}
              alt='metronic'
            />
          </span>
        </span>
      </a>

      <div className='  w-175px py-4'>
        {languages.map((l: any) => (
          <div
            className='menu-item px-3 '
            key={l.lang}
            onClick={() => {
              setLanguage(l.lang)
            }}
          >
            <a
              href='#'
              className={clsx('menu-link d-flex px-5', { active: l.lang === currentLanguage?.lang })}
            >
              <span className='symbol symbol-20px me-4'>
                <img className='rounded-1' src={toAbsoluteUrl('/media/flags/' + l.flag)} alt='metronic' />
              </span>
              {l.name}
            </a>
          </div>
        ))}
      </div>
    </div>
  )
}

export { LanguageHeader }
