import React, { useEffect, useState } from 'react'
import { KTSVG, toAbsoluteUrl } from '../../../../../_metronic/helpers'
import { useAuth } from '../../../auth'
import { Link, useParams } from 'react-router-dom';
import clsx from 'clsx';
import { useIntl } from 'react-intl';
function Footer() {
  const intl = useIntl();
  let name = useParams();
  const [path, setPath] = useState(name['*']);

  useEffect(() => {
    setPath(name['*']);
  }, [name])

  const { cookieBox, saveCookieBox } = useAuth()
  const sum = (cookieBox?.obje ?? []).reduce((accumulator, object) => {
    return accumulator + object.total
  }, 0)

  return (
    <>
      <div className='d-block  d-sm-block  navbar-expand-sm  d-md-none  mt-10'>
        <nav
          style={{ borderRadius: '8px 8px 0px 0px', backgroundColor: '#f4f4f4', borderTop: "1px solid #e2e2e2" }}
          className=' navbar-expand-sm navbar fixed-bottom navbar-light py-4  '
        >
          <div style={{ height: '40px' }} className='container-fluid  '>
            <Link
              style={{ width: '25%', color: "#60697b" }}
              className={clsx('navbar-brand btn  m-auto p-2 text-center ')}
              to='/'
            >
              <i
                style={{ color: '#60697b' }}
                className='las la-home svg-icon  me-0 pe-0 svg-icon-white fs-3x mb-2 '
              ></i>
              <div>{intl.formatMessage({ id: "MAIN.GENERAL.HOME" })}</div>
            </Link>
            <Link
              style={{ width: '25%', color: "#60697b" }}
              className='navbar-brand btn  m-auto p-2 text-center'
              to='/auth'
            >
              <i
                style={{ color: '#60697b' }}
                className='las la-user svg-icon  me-0 pe-0 svg-icon-white fs-3x mb-2 '
              ></i>
              <div> {intl.formatMessage({ id: "MAIN.GENERAL.LOGIN" })} / {intl.formatMessage({ id: "MAIN.GENERAL.MEMBER" })}</div>
            </Link>
            <Link
              style={{ width: '25%', color: "#60697b" }}
              className={clsx('navbar-brand btn mobilFooterBtn  m-auto p-2 fw-normal text-center ')}
              to='/basket'
            >
              <span
                style={{ position: 'absolute', color: 'green' }}
                className={clsx('badge badge-circle bg-warning ms-5 mt-3 ')}
              >
                {sum}
              </span>

              <i
                style={{ color: '#60697b' }}
                className={clsx('las la-shopping-basket svg-icon   me-0 pe-0 svg-icon-white fs-3x')}
              ></i>
              <div className=''> {intl.formatMessage({ id: "MAIN.GENERAL.MYBASKET" })}</div>
            </Link>

            <button
              style={{ width: '25%', color: "#60697b" }}
              type='button'
              className='navbar-brand btn mobilFooterBtn m-auto p-2 fw-normal text-center '
              data-bs-toggle='modal'
              data-bs-target='#kt_modal_2'
            >

              <i
                style={{ color: '#60697b' }}
                className='fa-regular fa-credit-card svg-icon  me-0 mb-1 pe-0 svg-icon-white fs-3x   '
              ></i>

              <div className=''> {intl.formatMessage({ id: "MAIN.MONEY.CURRENCY.DILPARA" })}</div>
            </button>

            {/* <a className="navbar-brand" href="#">Fixed bottom</a>
                    <a className="navbar-brand" href="#">Fixed bottom</a>
                    <a className="navbar-brand" href="#">Fixed bottom</a> */}
          </div>
        </nav>

      </div>
      <div className='	d-none d-md-block d-xl-block   d-xl-block d-xxl-block d-lg-none'>
        <div className="container" style={{ marginTop: "70px" }}>
          <div className="row">
            <div className="col-xl-3 text-center">
              <img className='w-100 mb-5' src={toAbsoluteUrl('/media/logos/bereket-dernegi-logo-1.png')} />
              <span className=' bold'><b>Bereket Derneği, Yurtiçi ve Yurtdışında insani ve islami yardım faaliyetlerinde bulunan bir hayır kurumudur.</b></span>
            </div>
            <div className="col-xl-3 text-center fs-5">
              <p className='mb-3'><b >Kurumsal</b></p>
              <p className='mb-2'><a href="https://www.bereket.org.tr/hakkimizda">Hakkımızda</a></p>
              <p className='mb-2'><a href="https://www.bereket.org.tr//degerlerimiz">Değerlerimiz</a></p>
              <p className='mb-2'><a href="https://www.bereket.org.tr/hesap-numaralari">Hesap Numaraları</a></p>
              <p className='mb-2'><a href="https://www.bereket.org.tr/iletisim">İletişim</a></p>
            </div>
            <div className="col-xl-3 text-center fs-5">
              <p className='mb-3'><b >Projelerimiz</b></p>
              <p className='mb-2'><a href="https://www.bereket.org.tr//su-kuyusu">Su Kuyusu</a></p>
              <p className='mb-2'><a href="https://www.bereket.org.tr/kurban-bagisi">Kurban Bağışı</a></p>
              <p className='mb-2'><a href="https://www.bereket.org.tr/iftar-bagisi">İftar Bağışı</a></p>
              <p className='mb-2'><a href="https://www.bereket.org.tr/hafiz-yetistiriyorum">Hafız Yetiştiriyorum</a></p>
            </div>
            <div className="col-xl-3 text-center">
              <img className='w-100 mb-5' src={toAbsoluteUrl('/media/logos/bereket-cagri-merkezi-1-3.png')} />
            </div>
          </div>
        </div>

        <div style={{ backgroundColor: "#0e7154" }} className='  d-flex flex-column flex-md-row flex-stack p-5 justify-content-center mt-5'>
          <div className='text-white text-center order-2 order-md-1 mb-5 mt-5'>
            <p><b>Bereket Derneği</b> © 2023 Tüm hakkı saklıdır.</p>
            <a href='https://www.dernekpro.com.tr/' target='_blank' className='text-muted text-hover-primary fw-bold me-2 fs-6 mb-5'>
              <img className='w-50 h-50' src={toAbsoluteUrl('/media/logos/dernekpro.png')} />
            </a>
            {/* <span className='text-gray-400 fw-bold me-1'>tarafından oluşturuldu</span> */}
          </div>
        </div>
      </div>
      <a href="https://api.whatsapp.com/send?phone=905360673384&text=Merhaba, bağış yapmak istiyorum." className="float-whatsapp" target="_blank">
        <i className="fa-brands fa-whatsapp my-float-whatsapp"></i>
      </a>
    </>
  )
}
export default Footer
