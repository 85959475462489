import axios from 'axios'
import { AuthModel, UserModel } from './_models'

const API_URL = process.env.REACT_APP_DATABASE_API_URL

export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`

export const LOGIN_URL = `${API_URL}/login`
export const REGISTER_URL = `${API_URL}users/register`
export const REQUEST_PASSWORD_URL = `${API_URL}forgot_password`
export const REQUEST_VERIFY_URL = `${API_URL}verify_code`
export const REQUEST_NEWPASS_URL = `${API_URL}new_pass`
export const REQUEST_CONTROL_URL = `${API_URL}control_change`

// Server should return AuthModel
export function login(email: string, password: string) {
  const all = axios.post<AuthModel>(LOGIN_URL, {
    email,
    password,
  })
  return all
}

// Server should return AuthModel
export function register(values: any) {
  const all = axios.post(REGISTER_URL, values)

  return all
}

// Server should return object => { result: boolean } (Is Email in DB)
export function requestPassword(email: string, phone: string, url: string) {
  return axios.post<{ result: any }>(REQUEST_PASSWORD_URL, {
    email,
    phone,
    url,
  })
}
export function VerifyCode(verifycode: string, id: any) {
  return axios.post<{ result: any }>(REQUEST_VERIFY_URL, {
    verifycode,
    id
  })
}
export function NewPasswordChange(newpass: string, verifyVal: any, id: any) {
  return axios.post<{ result: any }>(REQUEST_NEWPASS_URL, {
    newpass,
    verifyVal,
    id
  })
}
export function ChangeControl(control: any) {
  return axios.post<{ result: any }>(REQUEST_CONTROL_URL, {
    control,
  })
}


export function getUserByToken(token: string) {
  return axios.post<UserModel>(GET_USER_BY_ACCESSTOKEN_URL, {
    api_token: token,
  })
}
export function getActiveCalls(land_phone: string, dahili: string) {
  return axios.post<UserModel>(`${API_URL}callCenter/activeCalls`, {
    land_phone: land_phone,
    dahili: dahili
  })
}

export function addNewUser(userInfo: Object) {
  const all = axios.post<any>(`${API_URL}callCenter/addNewUser`, userInfo)
  return all;
}

export async function getLangData(lang: string) {
  const all = axios.post<any>(`${API_URL}getLangData/${lang}`,)
  return all;
}