import { useState } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Lottie from 'react-lottie-player'
import lottieJson from './data.json'
function Paymodal(props: any) {
    const [isOpen, setIsOpen] = useState(false);
    const [timer, setTimer] = useState(0);
    const [startTime, setStartTime] = useState(0);
    const [endTime, setEndTime] = useState(0);
    const [title, setTitle] = useState("Transitioning...");
    const intl = useIntl();
    return (

        <div>
            <Modal
                fade={'true'}
                {...props}
                size="md"
                className='modal fade'
                // aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>

                    <Modal.Title id="contained-modal-title-vcenter">
                        {title}
                        {intl.formatMessage({ id: "MAIN.BASKET.INFO_7" })}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className=''>
                    <Lottie

                        className=''
                        loop
                        animationData={lottieJson}
                        play
                        style={{ width: 250, height: 250, margin: '0 auto' }}
                    />
                    <h4 className='text-center text-success'> {intl.formatMessage({ id: "MAIN.BASKET.INFO_8" })}</h4>
                    {/* <p className='text-center'>
                           Ödeme İşleminiz Tamamlanmıştır.
                        </p> */}
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>{intl.formatMessage({ id: "MAIN.GENERAL.CLOSE" })}</Button>
                    {/* <Button onClick={props.onSubmitPay}>İşlem Tamamdır</Button> */}
                </Modal.Footer>
            </Modal>
        </div >

    );
}

export default Paymodal