import React, { useEffect, useState } from 'react';
import Card from './Card';
import '../../../styles/payButton.css'
import { toAbsoluteUrl } from './../../../../../../_metronic/helpers/AssetHelpers';
import Swal from 'sweetalert2';
import { FormattedMessage, injectIntl } from 'react-intl';
//@ts-ignore
export default class Step2 extends React.Component<Props> {
  constructor(props: any) {
    super(props);
  }
  toggle_degis = () => {
    this.props.setStep2Vals({ ...this.props.Step2Vals, toggle: !this.props.Step2Vals.toggle })
  }
  handleInputFocus = (e: any) => {
    this.props.setStep2Vals({ ...this.props.Step2Vals, focus: e.target.name });
  }
  handleInputChange = (e: any) => {
    const { name, value } = e.target;
    var valx = value;
    if (name === "expiry") {
      if (value !== "") {
        let numbers = value.replace(/\D/g, "");
        numbers = numbers.toString();
        if (numbers.length > 2) {
          valx = numbers.substring(0, 2) + "/" + numbers.substring(2);
        }
        valx = valx.substring(0, 5);
      } else {
        valx = "";
      }
    } else if (name === "number") {
      valx = valx.substring(0, 16);
    }
    this.props.setStep2Vals({ ...this.props.Step2Vals, [name]: valx })
  }

  render() {
    return (
      <div className="container">
        <div className='row justify-content-start mb-20 mt-n2 pb-5'>
          <div className="col-5   "></div>
          <div className="col-2   ">
            <label className="switch-pay ">
              <input onChange={() => this.toggle_degis()} className='pay-input ' type="checkbox" />
              <span className="slider"></span>
            </label>
          </div>
        </div>
        {this.props.Step2Vals.toggle === false ?
          <>
            <div className='row'>
              <div className="col-12">
                <div className='row mb-5 w-100'>
                  <div id="PaymentForm">
                    <div className="row align-items-center justify-content-center">
                      <div className="col-sm-12 mb-4 Êcol-md-6">
                        <Card
                          cvc={this.props.Step2Vals.cvc}
                          expiry={this.props.Step2Vals.expiry}
                          focused={this.props.Step2Vals.focus}
                          name={this.props.Step2Vals.name}
                          number={this.props.Step2Vals.number}
                          locale={{ valid: "Tarih" }}
                          placeholders={{ name: "İsim Soyisim" }}
                        />
                      </div>
                      <div className="col-sm-12 col-md-4 ms-10">
                        <form  >
                          <input
                            className='form-control mb-5'
                            type="text"
                            name="name"
                            pattern="[A-Za-z]{3}"
                            value={this.props.Step2Vals.name}
                            placeholder="Ad Soyad"
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                          />
                          <input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            className='form-control mb-5'
                            type="text"
                            name="number"
                            value={this.props.Step2Vals.number}
                            required
                            placeholder="Kart Numarası"
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                          />
                          <input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            className='form-control mb-5'
                            type="text"
                            name="expiry"
                            value={this.props.Step2Vals.expiry}
                            required
                            placeholder="Son Kullanma Tarihi"
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                          />
                          <input
                            onKeyPress={(event) => {
                              if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                              }
                            }}
                            className='form-control'
                            type="text"
                            name="cvc"
                            value={this.props.Step2Vals.cvc}
                            required
                            placeholder="CVC"
                            onChange={this.handleInputChange}
                            onFocus={this.handleInputFocus}
                          />
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
          :
          <>
            <img className='w-25 h-15' src={toAbsoluteUrl("/media/payment/ziraat.svg")} />
            <dl className="row w-100 ">

              <dt className="col-sm-3  h4">
                <FormattedMessage id="USER.STEP2.ACCOUNT_NAME" />: </dt>
              <dd className="col-sm-9   lead">Bereket Eğ. Sos. Yr. Der (Ziraat Katılım)</dd>

              <dt className="col-sm-3 h4"><FormattedMessage id="USER.STEP2.TL_IBAN" />: </dt>
              <dd className="col-sm-9  lead">
                <p>TR080020900001283728000001  (TL) <img onClick={() => {
                  navigator.clipboard.writeText('TR080020900001283728000001'); Swal.mixin({
                    toast: true,
                    position: 'top-end',
                    showConfirmButton: false,
                    timer: 3000,
                    timerProgressBar: true,
                    didOpen: (toast) => {
                      toast.addEventListener('mouseenter', Swal.stopTimer)
                      toast.addEventListener('mouseleave', Swal.resumeTimer)
                    }
                  }).fire({
                    icon: 'success',
                    title: 'Başarılı bir şekilde kopyalandı'
                  })
                }} className='ms-3 me-3 mb-1' src={toAbsoluteUrl('media/payment/copy.png')} /></p>
              </dd>
              <dt className="col-sm-3 h4"><FormattedMessage id="USER.STEP2.EURO_IBAN" />: </dt>
              <dd className="col-sm-9  lead">
                <p>TR240020900001283728000004 (EURO)
                  <img
                    className='ms-3 me-3 mb-1'
                    src={toAbsoluteUrl('media/payment/copy.png')}
                    onClick={() => {
                      navigator.clipboard.writeText('TR240020900001283728000004'); Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.addEventListener('mouseenter', Swal.stopTimer)
                          toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                      }).fire({
                        icon: 'success',
                        title: 'Başarılı bir şekilde kopyalandı'
                      })
                    }}
                  /></p>
              </dd>
              <dt className="col-sm-3 h4"><FormattedMessage id="USER.STEP2.DOLAR_IBAN" />: </dt>
              <dd className="col-sm-9  lead">
                <p>TR510020900001283728000003 (DOLAR)
                  <img
                    className='ms-3 me-3 mb-1'
                    src={toAbsoluteUrl('media/payment/copy.png')}
                    onClick={() => {
                      navigator.clipboard.writeText('TR510020900001283728000003'); Swal.mixin({
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                        didOpen: (toast) => {
                          toast.addEventListener('mouseenter', Swal.stopTimer)
                          toast.addEventListener('mouseleave', Swal.resumeTimer)
                        }
                      }).fire({
                        icon: 'success',
                        title: 'Başarılı bir şekilde kopyalandı'
                      })
                    }}
                  /></p>
              </dd>

              <dt className="col-sm-3 pb-sm-5 h4"><FormattedMessage id="USER.STEP2.DEFINITION" /></dt>
              <dd className="col-sm-12 col-md-9 pb-sm-5  lead"><b><FormattedMessage id="USER.STEP2.EXPLANATION" /></b></dd>

            </dl>

          </>}

      </div>
    );
  }
}