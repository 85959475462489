import axios, { AxiosResponse } from 'axios'
import { Response } from '../../../_metronic/helpers'

const API_URL = process.env.REACT_APP_DATABASE_API_URL
const USERS_URL = `${API_URL}/users/`

const getUserMainInfo = () => {
  const all = axios
    .post<any>(`${USERS_URL}getUserMainInfo`)
    .then((response: Response<any>) => response.data)
  return all
}
const getAdminMainInfo = () => {
  const all = axios
    .post<any>(`${USERS_URL}getAdminMainInfo`)
    .then((response: Response<any>) => response.data)
  return all
}

const getUserInfo = () => {
  const all = axios
    .post(`${API_URL}/users/userInfo`)
    //.then((response: AxiosResponse<Response<Categories>>) => response.data)
    .then((response) => response.data)
  return all
}

export { getUserMainInfo, getUserInfo, getAdminMainInfo }
