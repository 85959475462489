import clsx from 'clsx'
import { KTSVG } from '../../helpers'
import { useLayout } from '../core'
import { BestSellers, Orders, Stats, Tasks } from '../../partials'
import { useAuth } from '../../../app/modules/auth/core/Auth'

const Sidebar = () => {
  const { classes } = useLayout()
  const sidebarCSSClass = classes.sidebar
  const { currentUser, logout } = useAuth()
  return (
    <>
      {currentUser?.authority === '2' ?
        <></>
        :
        <>
          
        </>}

    </>
  )
}

export { Sidebar }
