/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { FC, useEffect, useState } from 'react'
import { useParams,useMatch } from 'react-router-dom';
import { getCustomerDetail, getCustomerMessages } from '../../../../app/modules/apps/donation-customer-tracking/customer-detail-page/core/_requests';
import { KTSVG } from '../../../helpers'
import { ChatInner } from '../../chat/ChatInner'

const DrawerMessenger: FC = () => {
  const matches = useMatch('admin/apps/customer-management/customers/user/:id');
  const [msgContent, setMsgContent] = useState<any>([]);
  const getContent = async (id: any) => {
    const { data: deger } = await getCustomerDetail(id)
    if (deger === undefined) {
      setMsgContent(undefined);
    } else {
      setMsgContent(deger);

    }
  }

  

  useEffect(() => {
    // getMedia(id);
    getContent(matches?.params.id);

  }, [])
  return (<div
    id='kt_drawer_chat'
    className='bg-white'
    data-kt-drawer='true'
    data-kt-drawer-user-id='0'
    data-kt-drawer-name='chat'
    data-kt-drawer-activate='true'
    data-kt-drawer-overlay='true'
    data-kt-drawer-width="{default:'300px', 'md': '500px'}"
    data-kt-drawer-direction='end'
    data-kt-drawer-toggle='#kt_drawer_chat_toggle'
    data-kt-drawer-close='#kt_drawer_chat_close'
  >
    <div className='card w-100 rounded-0' id='kt_drawer_chat_messenger'>
      <div className='card-header pe-5' id='kt_drawer_chat_messenger_header'>
        <div className='card-title'>
          <div className='d-flex justify-content-center flex-column me-3'>
            <a href='#' className='fs-4 fw-bolder text-gray-900 text-hover-primary me-1 mb-2 lh-1'>
              {msgContent.adi + " " + msgContent.soyadi}
            </a>

          </div>
        </div>

        <div className='card-toolbar'>


          <div className='btn btn-sm btn-icon btn-active-light-primary' id='kt_drawer_chat_close'>
            <KTSVG path='/media/icons/duotune/arrows/arr061.svg' className='svg-icon-2' />
          </div>
        </div>
      </div>

      <ChatInner isDrawer={true} id={Number(matches?.params.id)} />
    </div>
  </div>
  )
}

export { DrawerMessenger }
