/* eslint-disable react/jsx-no-target-blank */
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../helpers'
import { AsideMenuItemWithSub } from './AsideMenuItemWithSub'
import { AsideMenuItem } from './AsideMenuItem'
import { useAuth } from '../../../../app/modules/auth/core/Auth'
import { useEffect } from 'react'
import { Link } from 'react-router-dom'

export function AsideMenuMain() {
  const intl = useIntl()
  const { currentUser, logout } = useAuth()

  return (
    <>
      {/* <AsideMenuItem
        to='/dashboard'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
        fontIcon='bi-app-indicator'
      /> */}
      {/* <AsideMenuItem
        to='/builder'
        icon='/media/icons/duotune/arrows/arr001.svg'
        title='Layout Builder'
        fontIcon='bi-layers'
      /> */}
      {currentUser?.authority === '2' ?

        <div className='h3 ' >

          <div style={{margin:0}} className='menu-item text-center d-block mb-10'>
            <Link
              to='/'
              title='BAĞIŞ YAP'
            // fontIcon='bi-layers'
            ><span style={{borderRadius:'0px 15px 0px 15px ',color:'#ffc017'}} className='btn  btn-outline btn-outline-dashed btn-outline-white fw-bold'>
             <KTSVG path="/media/icons/duotune/ecommerce/ecm007.svg"  className="svg-icon-muted text-warning  svg-icon-2hx" />
             {intl.formatMessage({ id: "MAIN.CARD.BUTTON" })}
              </span></Link>
          </div>

          <AsideMenuItem
            to=''
            icon='/media/icons/duotune/arrows/arr001.svg'
            title={intl.formatMessage({ id: "USER.MENU.HOME" })}
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='donation-user-list'
            icon='/media/icons/duotune/arrows/arr001.svg'
            title={intl.formatMessage({ id: "USER.MENU.MY.DONATES" })}
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='donation-student-list'
            icon='/media/icons/duotune/arrows/arr001.svg'
            title={intl.formatMessage({ id: "USER.MENU.STUDENTS" })}
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='donation-user-notes'
            icon='/media/icons/duotune/arrows/arr001.svg'
            title={intl.formatMessage({ id: "USER.MENU.NOTES" })}
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='donation-user-otoode'
            icon='/media/icons/duotune/arrows/arr001.svg'
            title={intl.formatMessage({ id: "USER.MENU.OTOODEME" })}
            fontIcon='bi-layers'
          />
          <AsideMenuItem
            to='crafted/account/settings'
            icon='/media/icons/duotune/arrows/arr001.svg'
            title={intl.formatMessage({ id: "USER.MENU.MY.ACCOUNT" })}
            fontIcon='bi-layers'
          />

        </div>
        :
        <>
          {currentUser?.transactions.includes("11") ? <>
            <AsideMenuItem
              to='apps/donation-categories/categories'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title='KATEGORİLER'
              fontIcon='bi-layers'
            />

          </> : <></>}
          {currentUser?.transactions.includes("21") ? <>
            <AsideMenuItem
              to='apps/donation-management/donation'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={'BAĞIŞ KARTLARI'}
              fontIcon='bi-app-indicator'
            />
          </> : <></>}
          {currentUser?.transactions.includes("101") ? <>
            <AsideMenuItem
              to='apps/student-management/students'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={'HAFIZLAR'}
              fontIcon='bi-app-indicator'
            />
          </> : <></>}

          {currentUser?.transactions.includes("31") ? <>
            <AsideMenuItem
              to='apps/donation-tracking/users'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={'BAĞIŞLAR'}
              fontIcon='bi-app-indicator'
            />
          </> : <></>}


          {currentUser?.transactions.includes("41") ? <>
            <AsideMenuItem
              to='apps/donation-media/customers'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={'BAĞIŞ GRUPLARI'}
              fontIcon='bi-app-indicator'
            />
          </> : <></>}
          {currentUser?.transactions.includes("91") ? <>
            <AsideMenuItem
              to='apps/victim-management/customers'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={'KURBAN BAĞIŞLARI'}
              fontIcon='bi-app-indicator'
            />
          </> : <></>}
          {currentUser?.transactions.includes("51") ? <>
            <AsideMenuItem
              to='apps/customer-management/customers'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={'ÜYELER'}
              fontIcon='bi-app-indicator'
            />
          </> : <></>}


          {currentUser?.transactions.includes("61") ? <>
            <AsideMenuItem
              to='apps/donation-pay-management/users'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={'ÖDEMELER'}
              fontIcon='bi-app-indicator'
            />
          </> : <></>}
          {currentUser?.transactions.includes("111") ? <>
            <AsideMenuItem
              to='apps/donation-autopay-management/users'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={'OTOMATİK ÖDEME TALİMATLARI'}
              fontIcon='bi-app-indicator'
            />
          </> : <></>}


          {/* <AsideMenuItem
            to='apps/bank_transactions/customers'
            icon='/media/icons/duotune/arrows/arr001.svg'
            title={'BANKA HAREKETLERİ'}
            fontIcon='bi-app-indicator'
          /> */}
          {currentUser?.transactions.includes("71") ? <>
            <AsideMenuItem
              to='apps/call-center/customers'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={'ÇAĞRI MERKEZİ'}
              fontIcon='bi-app-indicator'
            />
          </> : <></>}

          {currentUser?.transactions.includes("81") ? <>
            <AsideMenuItem
              to='apps/authority/users'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={'YETKİLENDİRME'}
              fontIcon='bi-app-indicator'
            />
          </> : <></>}

          {currentUser?.transactions.includes("121") ? <>
            <AsideMenuItem
              to='apps/setting-management/customers'
              icon='/media/icons/duotune/arrows/arr001.svg'
              title={'GENEL AYARLAR'}
              fontIcon='bi-app-indicator'
            />
          </> : <></>}

        </>
      }


      {/* <AsideMenuItemWithSub
        to='/crafted/pages'
        title='Pages'
        fontIcon='bi-archive'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItemWithSub to='/crafted/pages/profile' title='Profile' hasBullet={true}>
          <AsideMenuItem to='/crafted/pages/profile/overview' title='Overview' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/projects' title='Projects' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/campaigns' title='Campaigns' hasBullet={true} />
          <AsideMenuItem to='/crafted/pages/profile/documents' title='Documents' hasBullet={true} />
          <AsideMenuItem
            to='/crafted/pages/profile/connections'
            title='Connections'
            hasBullet={true}
          />
        </AsideMenuItemWithSub>

        <AsideMenuItemWithSub to='/crafted/pages/wizards' title='Wizards' hasBullet={true}>
          <AsideMenuItem
            to='/crafted/pages/wizards/horizontal'
            title='Horizontal'
            hasBullet={true}
          />
          <AsideMenuItem to='/crafted/pages/wizards/vertical' title='Vertical' hasBullet={true} />
        </AsideMenuItemWithSub>
      </AsideMenuItemWithSub> */}


      {/* <AsideMenuItemWithSub
        to='/crafted/accounts'
        title='Accounts'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-person'
      >
        <AsideMenuItem to='/crafted/account/overview' title='Overview' hasBullet={true} />
        <AsideMenuItem to='/crafted/account/settings' title='Settings' hasBullet={true} />
      </AsideMenuItemWithSub> */}


      {/* <AsideMenuItemWithSub
        to='/error'
        title='Errors'
        fontIcon='bi-sticky'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/error/404' title='Error 404' hasBullet={true} />
        <AsideMenuItem to='/error/500' title='Error 500' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      {/* <AsideMenuItemWithSub
        to='/crafted/widgets'
        title='Widgets'
        icon='/media/icons/duotune/arrows/arr001.svg'
        fontIcon='bi-layers'
      >
        <AsideMenuItem to='/crafted/widgets/lists' title='Lists' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/statistics' title='Statistics' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/charts' title='Charts' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/mixed' title='Mixed' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/tables' title='Tables' hasBullet={true} />
        <AsideMenuItem to='/crafted/widgets/feeds' title='Feeds' hasBullet={true} />
      </AsideMenuItemWithSub> */}

      {/* APPS SİDE MENU START */}
      {/* <div className='menu-item'>
        <div className='menu-content pt-8 pb-2'>
          <span className='menu-section text-muted text-uppercase fs-8 ls-1'>Apps</span>
        </div>
      </div> */}

      {/* <AsideMenuItemWithSub
        to='/apps/chat'
        title='Chat'
        fontIcon='bi-chat-left'
        icon='/media/icons/duotune/arrows/arr001.svg'
      >
        <AsideMenuItem to='/apps/chat/private-chat' title='Private Chat' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/group-chat' title='Group Chart' hasBullet={true} />
        <AsideMenuItem to='/apps/chat/drawer-chat' title='Drawer Chart' hasBullet={true} />
      </AsideMenuItemWithSub> */}


      {/* APPS SİDE MENU END */}

      {/* <div className='menu-item'>
        <div className='menu-content'>
          <div className='separator mx-1 my-4'></div>
        </div>
      </div>



      <div className='menu-item'>
        <a
          target='_blank'
          className='menu-link'
          href={process.env.REACT_APP_PREVIEW_DOCS_URL + '/docs/changelog'}
        >
          <span className='menu-icon'>
            <KTSVG path='/media/icons/duotune/general/gen005.svg' className='svg-icon-2' />
          </span>
          <span className='menu-title'>Changelog {process.env.REACT_APP_VERSION}</span>
        </a>
      </div> */}
    </>
  )
}
