/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC } from 'react'
import { useIntl } from 'react-intl'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../../app/modules/auth'
import { KTSVG } from '../../../helpers'
import { UserMenu } from '../user-menu/UserMenu'
import { toAbsoluteUrl } from './../../../helpers/AssetHelpers';

const AsideUserMenu: FC = () => {
  const intl = useIntl()
  const { currentUser, logout } = useAuth()

  return (
    <>
      <div className='d-flex flex-stack'>
        {/* begin::Wrapper */}
        <div className='d-flex align-items-center'>
          {/* begin::Avatar */}
          <div className='symbol  symbol-40px'>
            <img className='' src={`${//@ts-ignore
              process.env.REACT_APP_MEDIA_API_URL + 'images/' + currentUser?.icon}`} onError={(e: any) => {
                e.target.onerror = null;
                e.target.src = `${toAbsoluteUrl('/media/avatars/blank.png')}`
              }} alt='avatar' />
          </div>
          {/* end::Avatar */}
          {/* begin::User info */}
          <div className='ms-2'>
            <a href='#' className='text-gray-800 text-hover-primary fs-6 fw-bolder lh-1'>
              {currentUser?.first_name} {currentUser?.first_name}
            </a>
            <span className='text-white fw-bold d-block fs-5 lh-2'>{currentUser?.adi} {currentUser?.soyadi}</span>
          </div>
          {/* end::User info */}
        </div>
        {/* end::Wrapper */}

        {/* begin::User menu */}
        <div className='ms-1'>
          {currentUser?.authority === '2' ?
            <>
              <Link onClick={logout} className='  ' to={'/'}  >
                <div
                  className='btn btn-sm btn-icon btn-active-color-secondary text-danger  fw-bold  '
                  data-kt-menu-trigger='click'
                  data-kt-menu-overflow='false'
                  data-kt-menu-placement='top-end'

                >
                  <i className="fa-solid fa-arrow-right-from-bracket me-2" />
                  {intl.formatMessage({ id: "USER.GENERAL.LOGOUT" })}
                  {/* <span className='menu-link position-relative ms-5 '>

                  </span> */}

                </div>
                <br />
                <span className='text-white position-relative'>
                </span>
              </Link>
            </>
            :
            <>
              <div
                className='btn btn-sm btn-icon btn-active-color-primary position-relative me-n2'
                data-kt-menu-trigger='click'
                data-kt-menu-overflow='false'
                data-kt-menu-placement='top-end'
              >

                <KTSVG path='/media/icons/duotune/coding/cod001.svg' className='svg-icon-1' />
              </div>
              <UserMenu /></>}

        </div>
        {/* end::User menu */}
      </div>
    </>
  )
}

export { AsideUserMenu }
