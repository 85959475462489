import clsx from 'clsx'
import { useFormik } from 'formik'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import * as Yup from 'yup'
import { useMutation } from 'react-query'
import { CookieBoxModel, useAuth } from '../../../../auth'
import 'react-phone-input-2/lib/bootstrap.css'
import PhoneInput from 'react-phone-input-2'
import tr from 'react-phone-input-2/lang/tr.json'
import Swal from 'sweetalert2'
import { useIntl } from 'react-intl'
function BasketCard(props: any) {

  function currencyFormat(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  const intl = useIntl();
  const ref = useRef(null)
  const { cookieBox, saveCookieBox, currentUser, genericValues, saveGenericValues } = useAuth()
  const [langData, setLangData] = useState<any>(genericValues?.langJson)
  useEffect(()=>{
    setLangData(genericValues?.langJson)
  },[genericValues])
  const initialValues = {
    name: String(props.items.values[props.order].name),
    lastname: '',
    email: '',
    phone: '',
    password: '',
    changepassword: '',
    acceptTerms: false,
  }
  const registrationSchema = Yup.object().shape({
    name: Yup.string().required(),
  })
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => { },
  })
  const checkBasketCard = async (itemProps: any, stat: any = 0) => {
    if (!currentUser && itemProps.items.info_type === 1 && stat === 1) {
      Swal.fire({
        title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_5" }),
        text: intl.formatMessage({ id: "MAIN.CARD.WARNING_5" }),
        icon: 'warning',
        timer: 2500
      })
      return false;
    }
    let item = cookieBox?.obje ?? []
    if (item) {
      saveCookieBox({
        ...{
          obje: (cookieBox?.obje ?? []).map((obj: any) => {
            if (obj.basket_id === itemProps.items.basket_id) {
              if (stat === 0) {
                if (obj.values[itemProps.order].error) {
                  if (obj.values[itemProps.order].other === true && obj.values[itemProps.order].autopay === false) {
                    obj.values[itemProps.order].error = false;
                  }
                }
                if (itemProps.items.pay_type === 7) {
                  if (obj.values[itemProps.order].error) {
                    if (obj.values[itemProps.order].other === false) {
                      obj.values[itemProps.order].error = false;
                    }
                  }
                }
                obj.values[itemProps.order].other = !obj.values[itemProps.order].other
              } else if (stat === 1) {
                if (obj.values[itemProps.order].error) {
                  if (obj.values[itemProps.order].other === false && obj.values[itemProps.order].autopay === true) {
                    obj.values[itemProps.order].error = false;
                  }
                }
                obj.values[itemProps.order].autopay = !obj.values[itemProps.order].autopay
              } else if (stat === 2) {
                if (obj.values[itemProps.order].error) {
                  if (obj.values[itemProps.order].other === true && obj.values[itemProps.order].autopay === false) {
                    obj.values[itemProps.order].error = false;
                  }
                }
                if (itemProps.items.pay_type === 7) {
                  if (obj.values[itemProps.order].error) {
                    if (obj.values[itemProps.order].other === false) {
                      obj.values[itemProps.order].error = false;
                    }
                  }
                }
                for (let i = 0; i < obj.values.length; i++) {
                  obj.values[i].other = !obj.values[i].other
                }
              }
              return { ...obj, values: obj.values }
            }

            return obj
          }),
        },
      } as CookieBoxModel)
    }


  }
  const updateBasketCard = async (elem: any, itemProps: any, itemOrder: any = -1) => {
    let item = cookieBox?.obje ?? []
    if (itemProps.items.pay_type === 6 && (elem.target.name === 'piece')) {
      let mon = (props.items.monthly - (props.items.taksit_odenen !== undefined ? props.items.taksit_odenen : 0));
      let apmon = Math.ceil(mon / elem.target.value)
      setAutoPayMonths(autoPayMonthOpt(apmon, ' Ay'))
    }
    if (item) {
      if (itemProps.items.pay_type === 6 && (itemProps.items.monthly - (itemProps.items.taksit_odenen !== undefined ? itemProps.items.taksit_odenen : 0)) < elem.target.value && elem.target.name === 'piece') {
        Swal.fire({
          title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_5" }),
          text: intl.formatMessage({ id: "MAIN.CARD.WARNING_6" }),
          icon: 'warning',
          timer: 2500
        })
        return false;
      }
      if (
        (Number(itemProps.items.info_type) === 2) &&
        elem.target.name === 'piece'
      ) {
        console.log("piece change")
        saveCookieBox({
          ...{
            obje: (cookieBox?.obje ?? []).map((obj: any) => {
              if (obj.basket_id === itemProps.items.basket_id) {
                if (elem.target.value > 0) {
                  if (Number(itemProps.items.total) < elem.target.value) {
                    const addArray = []
                    for (let ix = 0; ix < elem.target.value - Number(itemProps.items.total); ix++) {
                      addArray.push({
                        cardOrder: obj.values.length + 1,
                        piece: 1,
                        other: obj.values[0].other,
                        name: '',
                        phone: '',
                        autopay: false,
                        month: 0,
                        day: 0,
                      })
                    }
                    return {
                      ...obj,
                      total: elem.target.value,
                      values: obj.values.concat(addArray),
                    }
                  } else {
                    obj.values.splice(
                      elem.target.value - 1,
                      Number(itemProps.items.total) - Number(elem.target.value)
                    )
                    return { ...obj, total: elem.target.value, values: obj.values }
                  }
                }
              }

              return obj
            }),
          },
        } as CookieBoxModel)
      } else {

        if (itemOrder === -1) {
          itemOrder = itemProps.order
        }
        if (
          (Number(itemProps.items.info_type) === 3) &&
          elem.target.name === 'piece'
        ) {
          
          let addPiece = elem.target.value - itemProps.items.values[itemOrder]['piece'];
          let Quantity = itemProps.items.quantity
          let Target = (Math.round(((Number(itemProps.items.target)) / (genericValues?.forTL ?? 1)) * 100) / 100)
          if(itemProps.items.pay_type===4){
            let tot = (cookieBox?.obje ?? []).filter(x => x.card_id === itemProps.items.card_id).map(x => Number(x.quantity) * Number(x.total)).reduce((acc, val) => acc + val, 0)
            if (Math.round(tot + (addPiece * Quantity)) > Target) {
              Swal.fire({
                title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_1" }),
                text: intl.formatMessage({ id: "MAIN.CARD.WARNING_1" }),
                showConfirmButton: false,
                timer: 2500,
              })
              return false;
            }
          }else if(itemProps.items.pay_type===5){
            let tot = (cookieBox?.obje ?? []).filter(x => x.card_id === itemProps.items.card_id).map(x => Number(x.total)).reduce((acc, val) => acc + val, 0)
            if (Math.round(tot + (addPiece)) > Target) {
              Swal.fire({
                title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_1" }),
                text: intl.formatMessage({ id: "MAIN.CARD.WARNING_1" }),
                showConfirmButton: false,
                timer: 2500,
              })
              return false;
            }
          }
          

        }
        saveCookieBox({
          ...{
            obje: (cookieBox?.obje ?? []).map((obj: any) => {
              if (obj.basket_id === itemProps.items.basket_id) {
                let addTotal = 0
                if (elem.target.name === 'piece') {
                  addTotal = elem.target.value - obj.values[itemOrder]['piece']
                }

                obj.values[itemOrder][elem.target.name] = elem.target.value
                if (obj.values[itemOrder]['error']) {
                  if (itemProps.items.pay_type === 7) {
                    let errorCheck = 0;
                    if ((obj.values[itemOrder]['other'] === false || obj.values[itemOrder]['name'] === '' || obj.values[itemOrder]['phone'] === '')) {
                      errorCheck++;
                    }
                    if (errorCheck === 0) {
                      obj.values[itemOrder]['error'] = false
                    }
                  } else {
                    if (obj.values[itemOrder]['other'] === false && obj.values[itemOrder]['autopay'] === false) {
                      obj.values[itemOrder]['error'] = false
                    } else {
                      let errorCheck = 0;
                      if (obj.values[itemOrder]['other'] && (obj.values[itemOrder]['name'] === '' || obj.values[itemOrder]['phone'] === '')) {
                        errorCheck++;
                      }
                      if (obj.values[itemOrder]['autopay'] && (obj.values[itemOrder]['month'] === 0 || obj.values[itemOrder]['day'] === 0)) {
                        errorCheck++;
                      }
                      if (errorCheck === 0) {
                        obj.values[itemOrder]['error'] = false
                      }
                    }
                  }
                }

                return { ...obj, total: Number(obj.total) + addTotal, values: obj.values }
              }

              return obj
            }),
          },
        } as CookieBoxModel)
      }

    }
    //formik.values.name = elem.target.value
  }
  const deleteBasketCard = async (itemProps: any) => {
    let item = cookieBox?.obje ?? []
    if (item) {
      if (Number(itemProps.items.info_type) === 1) {
        if (Number(item[itemProps.index_key].values.length) <= 1) {
          saveCookieBox({
            ...{
              obje: (cookieBox?.obje ?? []).filter((employee: any) => {
                // 👇️ remove object that has id equal to 2
                return employee.basket_id !== itemProps.items.basket_id
              }),
            },
          } as CookieBoxModel)
        } else {
          saveCookieBox({
            ...{
              obje: (cookieBox?.obje ?? []).map((obj: any) => {
                // 👇️ if id equals 2, update country property
                if (obj.basket_id === itemProps.items.basket_id) {
                  let deleteTotal = Number(obj.values[itemProps.order]['piece']) ?? 1
                  obj.values.splice(itemProps.order, 1)
                  return { ...obj, total: obj.total - deleteTotal, values: obj.values }
                }
                // 👇️ otherwise return object as is
                return obj
              }),
            },
          } as CookieBoxModel)
        }
      } else if (Number(itemProps.items.info_type) === 2 || 3) {

        saveCookieBox({
          ...{
            obje: (cookieBox?.obje ?? []).filter((employee: any) => {
              // 👇️ remove object that has id equal to 2
              return employee.basket_id !== itemProps.items.basket_id
            }),
          },
        } as CookieBoxModel)
      }
    }
  }
  const autoPayMonthOpt = (len: any = 36, optTag: any = 'Ay') => {
    const opts = []
    for (let opt = 1; opt <= len; opt++) {
      opts.push(<option value={opt}>{opt + optTag}</option>)
    }
    return opts
  }
  const [autoPayMonths, setAutoPayMonths] = useState<any>("");
  useEffect(() => {
    if (props.items.pay_type === 6) {
      let mon = (props.items.monthly - (props.items.taksit_odenen !== undefined ? props.items.taksit_odenen : 0));
      let apmon = Math.ceil(mon / props.items.total)
      setAutoPayMonths(autoPayMonthOpt(apmon, ' Ay'))
    } else {
      setAutoPayMonths(autoPayMonthOpt(36, ' Ay'))
    }
  }, [])

  const [allForOne, setAllForOne] = useState<boolean>(false);
  const checkAllForOne = async () => {
    let cname = "";
    let cphone = "";
    if (currentUser) {
      cname = currentUser.adi + " " + currentUser.soyadi
      cphone = String(currentUser.accountPhoneNumber)
    } else {
      cname = props.customer_name
      cphone = props.customer_phone
    }
    if (allForOne === false) {
      if (cname !== '' && cphone !== "") {
        setAllForOne(!allForOne)
        saveCookieBox({
          ...{
            obje: (cookieBox?.obje ?? []).map((obj: any) => {
              if (obj.basket_id === props.items.basket_id) {
                for (let i = 0; i < obj.values.length; i++) {
                  obj.values[i].name = cname
                  obj.values[i].phone = cphone
                }

                return { ...obj, values: obj.values }
              }

              return obj
            }),
          },
        } as CookieBoxModel)
      } else {
        Swal.fire({
          title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_5" }),
          text: intl.formatMessage({ id: "MAIN.CARD.WARNING_7" }),
          icon: 'warning',
          timer: 2500
        })
      }
    } else {
      setAllForOne(!allForOne)
      saveCookieBox({
        ...{
          obje: (cookieBox?.obje ?? []).map((obj: any) => {
            if (obj.basket_id === props.items.basket_id) {
              for (let i = 0; i < obj.values.length; i++) {
                obj.values[i].name = ""
                obj.values[i].phone = ""
              }
              return { ...obj, values: obj.values }
            }

            return obj
          }),
        },
      } as CookieBoxModel)
    }
  }
  const financial = (x: any) => Number.parseFloat(x)
  useEffect(()=>{console.log("piece change cookie",cookieBox?.obje)},[cookieBox])
  return (
    <div className='card card-custom  shadow mb-5' style={{ width: '100%' }}>
      <div className='card-body'>
        <div className='row justify-content-between '>
          <div
            className='col-3 hstack gap-3 d-none d-sm-none d-md-block '
            style={{ borderRight: '2px solid #919191' }}
          >
            <img
              className='img-fluid '
              style={{ width: '100%', height: '100%', borderRadius: '8px', maxHeight: '140px' }}
              src={`${process.env.REACT_APP_MEDIA_API_URL}${props.items.icon}`}
            />
          </div>
          <div className='col-xs-12 col-md-6'>
            <h3>{langData["DB.DONATE_LIST.VALUE_"+props.items.card_id]}</h3>
            {Number(props.items.info_type) === 1 || Number(props.items.info_type) === 3 ? (
              <>
                <div className='mt-5 mb-3'>
                  <div className='form-check form-check-custom form-check-solid '>
                    <input
                      ref={ref}
                      checked={props.items.values[props.order].other || false}
                      onChange={() => checkBasketCard(props)}
                      className='form-check-input accordion collapsed'
                      type='checkbox'
                      value=''
                      id={`flexCheckDefault1${props.items.basket_id}`}
                    />
                    <label htmlFor={`flexCheckDefault1${props.items.basket_id}`} className='form-check-label'>
                      {intl.formatMessage({ id: "MAIN.BASKET.CHECKBOX_1" })}
                    </label>
                  </div>

                  {props.items.values[props.order].other ? (
                    <div className='row bg-gray-200 rounded ms-0 mt-2 '>
                      <div className='col-xs-12 col-md-6 p-2'>
                        <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: "MAIN.GENERAL.NAME" })}</label>
                        <input
                          ref={ref}
                          type='text'
                          className={clsx(
                            'form-control form-control-lg form-control-solid',
                            {
                              'is-invalid': props.items.values[props.order].error && props.items.values[props.order].name === '',
                            },
                            {
                              'is-valid': props.items.values[props.order].name !== '',
                            }
                          )}
                          placeholder='İsim Soyisim Giriniz'
                          autoComplete='off'
                          name='name'
                          value={props.items.values[props.order].name}
                          onChange={(e) => updateBasketCard(e, props)}
                        />
                        {
                          (props.items.values[props.order].error && props.items.values[props.order].name === '' && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{intl.formatMessage({ id: "MAIN.BASKET.REQUIRED_1" })}</span>
                              </div>
                            </div>
                          ))}
                      </div>
                      <div className='col-xs-12 col-md-6 p-2'>
                        <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: "MAIN.GENERAL.PHONE" })}</label>
                        <PhoneInput
                          enableSearch
                          containerClass={''}
                          inputStyle={{ backgroundColor: '#F5F8FA', width: '100%', height: '50%' }}
                          inputProps={{
                            name: 'phone',
                            required: true,
                            autoFocus: true,
                            ref: ref
                          }}
                          // {...formik.getFieldProps('phone')}
                          value={props.items.values[props.order].phone}
                          country={'tr'}
                          onChange={(e) => updateBasketCard({ target: { name: 'phone', value: e } }, props)}
                        />
                        {
                          (props.items.values[props.order].error && props.items.values[props.order].phone === '' && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{intl.formatMessage({ id: "MAIN.BASKET.REQUIRED_2" })}</span>
                              </div>
                            </div>
                          ))}
                      </div>
                    </div>
                  ) : (
                    <></>
                  )}
                </div>

                {/* OTOMATİK ÖDEME TALİMATI START  ???????*/}
                {Number(props.items.info_type) === 1 ? (
                  <div className='mb-3'>
                    <div className='form-check form-check-custom form-check-solid'>
                      <input
                        ref={ref}
                        className={clsx('form-check-input', currentUser ? '' : 'disabled')}
                        type='checkbox'
                        value=''
                        id={`flexCheckDefault2${props.items.basket_id}`}
                        checked={props.items.values[props.order].autopay || false}
                        onChange={() => checkBasketCard(props, 1)}
                      />
                      <label htmlFor={`flexCheckDefault2${props.items.basket_id}`} className='form-check-label'>
                        {intl.formatMessage({ id: "MAIN.BASKET.CHECKBOX_2" })}
                      </label>
                    </div>
                    {props.items.values[props.order].autopay ? (
                      <div className='row bg-gray-200 rounded ms-0 mt-2 '>
                        <div className='col-xs-12 col-md-6 p-2'>
                          <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: "MAIN.BASKET.MONTH.SELECT" })}</label>
                          <select
                            ref={ref}
                            className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {
                                'is-invalid': props.items.values[props.order].error && props.items.values[props.order].month === 0,
                              },
                              {
                                'is-valid': props.items.values[props.order].month !== 0,
                              }
                            )}
                            title={intl.formatMessage({ id: "MAIN.BASKET.MONTH.SELECT" })}
                            autoComplete='off'
                            name='month'
                            onChange={(e) => updateBasketCard(e, props)}
                            value={props.items.values[props.order].month}
                          >
                            <option>{intl.formatMessage({ id: "MAIN.GENERAL.SELECT" })}</option>
                            {autoPayMonths}
                          </select>
                          <small>{intl.formatMessage({ id: "MAIN.BASKET.INFO_1" })}</small>
                          {
                            (props.items.values[props.order].error && props.items.values[props.order].month === 0 && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{intl.formatMessage({ id: "MAIN.BASKET.REQUIRED_3" })}</span>
                                </div>
                              </div>
                            ))}
                        </div>
                        <div className='col-xs-12 col-md-6 p-2'>
                          <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: "MAIN.GENERAL.SELECT.DAY" })}</label>
                          <select
                            ref={ref}
                            className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {
                                'is-invalid': props.items.values[props.order].error && props.items.values[props.order].day === 0,
                              },
                              {
                                'is-valid': props.items.values[props.order].day !== 0,
                              }
                            )}
                            title='Gün seçiniz'
                            autoComplete='off'
                            name='day'
                            onChange={(e) => updateBasketCard(e, props)}
                            value={props.items.values[props.order].day}
                          >
                            <option>{intl.formatMessage({ id: "MAIN.GENERAL.SELECT" })}</option>
                            {autoPayMonthOpt(36, '.Gün')}
                          </select>
                          <small>{intl.formatMessage({ id: "MAIN.BASKET.INFO_2" })}</small>
                          {
                            (props.items.values[props.order].error && props.items.values[props.order].day === 0 && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{intl.formatMessage({ id: "MAIN.BASKET.REQUIRED_4" })}</span>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>) : (<></>)}

                {/* OTOMATİK ÖDEME TALİMATI END */}
              </>
            ) : (
              props.items.values.map((val: any, index: any) => (
                <div className='mt-5 mb-3' key={index}>
                  {Number(props.items.info_type) === 2 ? (
                    <>
                      {index == 0 ?
                        <>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              ref={ref}
                              checked={allForOne}
                              onChange={() => checkAllForOne()}
                              className={
                                clsx(
                                  'form-check-input accordion collapsed'
                                )
                              }
                              type='checkbox'
                              id={`flexCheckAllDefault${index}`}
                            />
                            <label htmlFor={`flexCheckAllDefault${index}`} className='form-check-label'>
                            {intl.formatMessage({ id: "MAIN.BASKET.CHECKBOX_3" })}
                            </label>
                          </div>
                          <br></br>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              ref={ref}
                              checked={val.other || false}
                              onChange={() => checkBasketCard(props, 2)}
                              className={
                                clsx(
                                  'form-check-input accordion collapsed',
                                  {
                                    'is-invalid': props.items.values[index].error && props.items.values[index].other === false,
                                  },
                                  {
                                    'is-valid': props.items.values[index].other !== false,
                                  }
                                )
                              }
                              type='checkbox'
                              id={`flexCheckDefault${props.items.basket_id + "-" + index}`}
                            />
                            <label htmlFor={`flexCheckDefault${props.items.basket_id + "-" + index}`} className='form-check-label'>
                            {intl.formatMessage({ id: "MAIN.BASKET.CHECKBOX_4" })}
                            </label>
                          </div>

                        </>

                        : <></>}
                      <div className='row bg-gray-200 rounded ms-0 mt-2 '>
                        <div className='col-xs-12 col-md-6 p-2'>
                          <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: "MAIN.GENERAL.NAME" })}</label>
                          <input
                            ref={ref}
                            type='text'
                            className={clsx(
                              'form-control form-control-lg form-control-solid',
                              {
                                'is-invalid': props.items.values[index].error && props.items.values[index].name === '',
                              },
                              {
                                'is-valid': props.items.values[index].name !== '',
                              }
                            )}
                            placeholder={intl.formatMessage({ id: "MAIN.BASKET.INFO_3" })}
                            autoComplete='off'
                            name='name'
                            value={val.name}
                            onChange={(e) => updateBasketCard(e, props, index)}
                          />
                          {(props.items.values[index].error && props.items.values[index].name === '' && (
                            <div className='fv-plugins-message-container'>
                              <div className='fv-help-block'>
                                <span role='alert'>{intl.formatMessage({ id: "MAIN.BASKET.REQUIRED_1" })}</span>
                              </div>
                            </div>
                          ))}
                        </div>
                        <div className='col-xs-12 col-md-6 p-2'>
                          <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: "MAIN.GENERAL.PHONE" })}</label>
                          <PhoneInput
                            enableSearch
                            localization={tr}
                            containerClass={''}
                            inputStyle={{ backgroundColor: '#F5F8FA', width: '100%', height: '50%' }}
                            inputProps={{
                              name: 'phone',
                              required: true,
                              autoFocus: true,
                              ref: ref
                            }}
                            {...formik.getFieldProps('phone')}
                            value={props.items.values[index].phone}
                            country={'tr'}
                            onChange={(e) => updateBasketCard({ target: { name: 'phone', value: e } }, props, index)}
                          // onChange={(phone: any) => formik.values.phone = phone}
                          />
                          {
                            (props.items.values[index].error && props.items.values[index].phone === '' && (
                              <div className='fv-plugins-message-container'>
                                <div className='fv-help-block'>
                                  <span role='alert'>{intl.formatMessage({ id: "MAIN.BASKET.REQUIRED_2" })}</span>
                                </div>
                              </div>
                            ))}
                        </div>
                      </div>
                    </>) : (<></>)}




                </div>
              ))
            )}
          </div>
          <div className='col-xs-12 col-md-3 text-center d-flex flex-row-reverse align-items-center'>
            <div className='ms-5 me-n5'>
              <a
                //href='#'
                //   onClick={() => deleteBasketCard(index, val.basket_id)}
                onClick={() => deleteBasketCard(props)}
                className='btn btn-link btn-color-danger btn-active-color-primary  '
              >
                <i className='bi bi-dash-circle fs-3x '></i>
              </a>
            </div>
            <div className=''>
              <h3 className='mb-1 ms-n5 mb-4'>
                {currencyFormat(financial(props.items.quantity * props.items.values[props.order].piece))} {(genericValues?.currSym ?? '₺')}
              </h3>

              <div className=' text-center '>
                <span className=''> {currencyFormat(financial(props.items.quantity))} {(genericValues?.currSym ?? '₺')} x</span>
                <input
                  ref={ref}
                  min={1}
                  max={props.items.monthly > 0 ? (props.items.monthly - (props.items.taksit_odenen !== undefined ? props.items.taksit_odenen : 0)) : 999999999}
                  style={{ width: '4.5rem', height: '30px', position: 'unset', display: 'inline' }}
                  className='form-control  ms-2'
                  type={'number'}
                  onWheel={() => (document.activeElement as HTMLElement).blur()}
                  name='piece'
                  value={
                    Number(props.items.info_type) === 1 || Number(props.items.info_type) === 3
                      ? props.items.values[props.order].piece
                      : Number(props.items.total)
                  }
                  onChange={(e) => updateBasketCard(e, props)}
                />
                {Number(props.items.pay_type === 6) ?
                  <div>
                    {props.items.taksit_odenen !== undefined ?
                      <small>
                        {intl.formatMessage({ id: "MAIN.BASKET.INFO_4" })} :
                        <b>{props.items.taksit_odenen} {intl.formatMessage({ id: "MAIN.CARD.MONTH" })} </b>
                        {intl.formatMessage({ id: "MAIN.BASKET.INFO_5" })} :
                        <b>{props.items.monthly - props.items.taksit_odenen} {intl.formatMessage({ id: "MAIN.CARD.MONTH" })}</b>
                      </small>
                      :
                      <small>{intl.formatMessage({ id: "MAIN.CARD.SPONSOR.TIME" })} :
                        <br></br>
                        <b>{props.items.monthly} {intl.formatMessage({ id: "MAIN.CARD.MONTH" })}</b>
                      </small>
                    }
                  </div>
                  :
                  <></>}

              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default BasketCard
