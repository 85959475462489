import clsx from 'clsx'
import { useFormik, Field, ErrorMessage } from 'formik'
import React, { FC, useEffect, useState } from 'react'
import * as Yup from 'yup'
import { CookieBoxModel, useAuth } from '../../../../auth'
import { basketValues, getDonateCardInfo, getRecommendedDonates, getStudentCardInfo } from '../../../core/_requests'
import BasketCard from './BasketCard'
import 'react-phone-input-2/lib/bootstrap.css'
import PhoneInput from 'react-phone-input-2'
import tr from 'react-phone-input-2/lang/tr.json'
import './bagis.css';
// import PhoneInput from 'react-phone-number-input';
import PropTypes from 'prop-types';
import 'react-phone-number-input/style.css';
import { getIn } from 'formik';
import Swal from 'sweetalert2'
import { Link, useParams, useSearchParams } from 'react-router-dom'
import { useIntl } from 'react-intl'
const Step1 = (props: any) => {

  function currencyFormat(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }
  let [searchParams, setSearchParams] = useSearchParams();
  const intl = useIntl();
  const [buttonCheck, setButtonCheck] = useState(false);
  const [recommendedDonates, setRecommendedDonates] = useState<any>([]);
  const onLoadStep1 = async () => {
    await getRecommendedDonates().then((data: any) => { setRecommendedDonates(data) });
  };

  const [studentPay, setStudentPay] = useState<any>([]);
  const getStudentInfo = async (id: any) => {
    await getStudentCardInfo(id).then((data: any) => { setStudentPay(data) });
  };

  const getDonateInfo = async (id: number, amount: number) => {
    await getDonateCardInfo(id).then((data: any) => { setStudentPay({ ...data, recommended_amount: amount }) });
  };

  const { cookieBox, saveCookieBox, currentUser, genericValues, saveGenericValues } = useAuth()
  const totalAmount = (cookieBox?.obje ?? []).reduce((accumulator, object) => {
    return accumulator + (object.total * object.quantity)
  }, 0)

  useEffect(() => {
    if (studentPay.card_id !== undefined) {
      if (genericValues?.studenCheck !== undefined && studentPay?.studen_check_id !== undefined) {
        addRecommendedDonate(studentPay, false)
      } else if (searchParams.get("id") !== null && searchParams.get("amount") !== null) {
        addRecommendedDonate(studentPay, false)
      }
    }
  }, [studentPay])

  useEffect(() => {
    props.setStep2Vals({ ...props.Step2Vals, cardobject: cookieBox?.obje, amount: totalAmount })
  }, [cookieBox])

  useEffect(() => {
    if (genericValues?.studenCheck !== undefined) {
      getStudentInfo(genericValues.studenCheck)
      saveCookieBox(undefined)
    }
    if (searchParams.get("id") !== null && searchParams.get("amount") !== null) {
      let pos = (cookieBox?.obje ?? [])
        .map((e: any) => e.basket_id)
        .indexOf(searchParams.get("id") + '-' + searchParams.get("amount"))

      if (pos === -1) {
        getDonateInfo(Number(searchParams.get("id")), Number(searchParams.get("amount")))
      }
    }
    onLoadStep1();
  }, [])

  const addRecommendedDonate = async (val: any, stat: boolean = true) => {

    var inputQuantity = val.recommended_amount
    let inputPiece = 1
    let inputMonth = 0

    if (Number(inputQuantity) > 0) {
      val.quantity = Number(inputQuantity)

      val.currentTotal = inputPiece
      const addArray: {
        cardOrder: any
        piece: number
        other: boolean
        name: string
        phone: string
        autopay: boolean
        month: number
        day: number
        error: boolean
      }[] = []
      if (val.info_type === 1) {
        addArray.push({
          cardOrder: (val.values ?? []).length + 1,
          piece: inputPiece,
          other: false,
          name: '',
          phone: '',
          autopay: false,
          month: 0,
          day: 0,
          error: false
        })
      } else if (val.info_type === 2 || 3) {
        for (let ix = 0; ix < inputPiece; ix++) {
          addArray.push({
            cardOrder: (val.values ?? []).length + ix + 1,
            piece: 1,
            other: false,
            name: '',
            phone: '',
            autopay: false,
            month: 0,
            day: 0,
            error: false
          })
        }
      }
      let pos = (cookieBox?.obje ?? [])
        .map((e: any) => e.basket_id)
        .indexOf(val.card_id + '-' + val.quantity)
      if (val.pay_type == 6) {
        pos = -1;
      }
      if (pos === -1) {
        if (val.pay_type === 4) {
          let tot = (cookieBox?.obje ?? []).filter(x => x.card_id === val.card_id).map(x => Number(x.quantity) * Number(x.total)).reduce((acc, val) => acc + val, 0)
          if ((Number(val.amount) + Number(inputQuantity) + Number(tot)) > Number(val.target)) {
            Swal.fire({
              title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_1" }),
              text: intl.formatMessage({ id: "MAIN.CARD.WARNING_1" }),
              icon: 'warning',
              showConfirmButton: false,
              timer: 2500,
            })
            return false;
          }
        } else if (val.pay_type === 5) {
          let tot = (cookieBox?.obje ?? []).filter(x => x.card_id === val.card_id).map(x => Number(x.total)).reduce((acc, val) => acc + val, 0)
          if ((Number(val.count) + Number(inputPiece) + Number(tot)) > Number(val.target)) {
            Swal.fire({
              title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_1" }),
              text: intl.formatMessage({ id: "MAIN.CARD.WARNING_1" }),
              icon: 'warning',
              showConfirmButton: false,
              timer: 2500,
            })
            return false;
          }
        }
        if (val.pay_type == 6) {
          val.basket_id = val.card_id + '-' + val.quantity + "-" + ((cookieBox?.obje ?? []).length + 1)
        } else {
          val.basket_id = val.card_id + '-' + val.quantity
        }

        val.total = inputPiece

        val.values = addArray
        if (cookieBox?.obje === undefined) {
          saveCookieBox({ ...{ obje: [{ ...val }] } } as CookieBoxModel)
        } else {
          saveCookieBox({ ...{ obje: [...(cookieBox?.obje ?? []), { ...val }] } } as CookieBoxModel)
        }
      } else {
        if (val.pay_type === 4) {
          let tot = (cookieBox?.obje ?? []).filter(x => x.card_id === val.card_id).map(x => Number(x.quantity) * Number(x.total)).reduce((acc, val) => acc + val, 0)
          if ((Number(val.amount) + Number(inputQuantity) + Number(tot)) > Number(val.target)) {
            Swal.fire({
              title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_1" }),
              text: intl.formatMessage({ id: "MAIN.CARD.WARNING_1" }),
              icon: 'warning',
              showConfirmButton: false,
              timer: 2500,
            })
            return false;
          }
        } else if (val.pay_type === 5) {
          let tot = (cookieBox?.obje ?? []).filter(x => x.card_id === val.card_id).map(x => Number(x.total)).reduce((acc, val) => acc + val, 0)
          if ((Number(val.count) + Number(inputPiece) + Number(tot)) > Number(val.target)) {
            Swal.fire({
              title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_1" }),
              text: intl.formatMessage({ id: "MAIN.CARD.WARNING_1" }),
              icon: 'warning',
              showConfirmButton: false,
              timer: 2500,
            })
            return false;
          }
        }
        saveCookieBox({
          ...{
            obje: (cookieBox?.obje ?? []).map((obj: any) => {
              // 👇️ if id equals 2, update country property
              if (obj.basket_id === val.card_id + '-' + val.quantity) {

                return {
                  ...obj,
                  total: obj.total + inputPiece,
                  values: obj.values.concat(addArray),
                }
              }

              // 👇️ otherwise return object as is
              return obj
            }),
          },
        } as CookieBoxModel)
      }
      if (stat) {
        Swal.fire({
          title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_2" }),
          text: intl.formatMessage({ id: "MAIN.CARD.WARNING_2" }),
          icon: 'success',
          showConfirmButton: false,
          timer: 1500,
        })
      }
    } else {
      Swal.fire({
        title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_3" }),
        text: intl.formatMessage({ id: "MAIN.CARD.WARNING_3" }),
        icon: 'info',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }

  return (
    <div>
      {totalAmount === 0 ? (
        <div className='container '>
          <div className='text-center  align-items-center justify-content-center'>
            <Link style={{ margin: '0 auto', backgroundColor: '#0E7154' }} className='btn btn text-white  text-center align-items-center justify-content-center  ' to={'/'}>Bağış Yap</Link>
          </div>
        </div>
      ) :
        (<>
          <div className='row mb-1 '>
            <div className='col-12'>
              <h3 className='mt-5 mb-5'>{intl.formatMessage({ id: "MAIN.GENERAL.MYBASKET" })}</h3>
              <div className='row justify-content-between '>

                <div className='col-sm-12 col-md-8'>
                  {(cookieBox?.obje ?? []).map((items: any, index: any) => {
                    {
                      const rows = []
                      if (Number(items.info_type) === 1 || Number(items.info_type) === 3) {
                        items.values.map((val: any, vlin: any) =>
                          rows.push(
                            <BasketCard
                              key={index.toString() + '-' + vlin.toString()}
                              items={items}
                              index_key={index}
                              order={vlin}
                            />
                          )
                        )
                      } else if (Number(items.info_type) === 2 ) {
                        rows.push(
                          <BasketCard
                            key={index.toString() + '-0'}
                            items={items}
                            index_key={0}
                            order={0}
                            customer_name={props.Step2Vals.customer_name}
                            customer_phone={props.Step2Vals.customer_phone}
                          />
                        )
                      }
                      return <div key={index}>{rows}</div>
                    }
                  })}
                  <div className='col-sm-12 mb-xs-10 mt-sm-15 col-md-12 '>
                    {
                      (recommendedDonates ?? []).map((val: any, key: any) => {
                        return (
                          <div key={key} style={{ boxShadow: '0 0 20px rgba(0, 0, 0, 0.2) ', backgroundColor: '' }} className=" text-success p-2 text-hover-inverse-light bg-hover-light   rounded mb-4">
                            <a href="#" onClick={() => (addRecommendedDonate(val))}>
                              <i className="fa-solid fa-heart-circle-plus fs-2x text-success mx-2"></i>
                              <span className='mx-4 h4'>{val.title}</span>
                            </a>
                          </div>
                        )
                      })
                    }
                  </div>
                </div>
                <div className='col-sm-12 col-md-4  '>
                  <div style={{ top: '6em' }} className='card card-custom shadow sticky-lg-top'>
                    <div className='card-body'>
                      <div className='row justify-content-between '>
                        <label className=' mb-5    h1'>{intl.formatMessage({ id: "MAIN.BASKET.TITLE_1" })}</label>
                        {!currentUser ?
                          <>
                            {/*  begin::Form  NAME INPUT */}
                            <div className='col-sm-12  col-md-12'>
                              <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: "MAIN.GENERAL.NAME" })}</label>
                              <input
                                value={props.Step2Vals.customer_name}
                                type='text'
                                className='form-control form-control-lg form-control-solid'
                                name='customer_name'
                                required={true}
                                onChange={(e: any) => { props.setStep2Vals({ ...props.Step2Vals, customer_name: e.target.value }) }}
                              />
                              <div className='text-danger mt-2'>
                                <ErrorMessage name='customer_name' />
                              </div>
                            </div>
                            {/* end::Form NAME INPUT */}

                            {/* begin::Form  PHONE INPUT  */}
                            <div className='col-sm-12  col-md-12 '>
                              <div className='fv-row mb-5'>
                                <label className='form-label fw-bolder text-dark fs-6'>{intl.formatMessage({ id: "MAIN.GENERAL.PHONE" })}</label>

                                <PhoneInput
                                  isValid={(value, country) => {
                                    //@ts-ignore
                                    if (country['countryCode'] === '90' &&
                                      value.length !== 12) {
                                      setButtonCheck(true);
                                      // document.getElementById("next").disabled=true;
                                      return (intl.formatMessage({ id: "MAIN.GENERAL.INVALID.PHONE" }) + ': ' + value + ' ')

                                    } else {
                                      setButtonCheck(false);
                                      // props.current.goNext()
                                      return true;
                                    }
                                  }}
                                  value={props.Step2Vals.customer_phone}
                                  enableSearch
                                  containerClass={''}
                                  inputStyle={{ backgroundColor: '#F5F8FA', width: '100%', height: '50%' }}
                                  inputProps={{
                                    type: 'text',
                                    name: 'customer_phone',
                                    required: true,
                                    autoFocus: true,
                                  }}
                                  onChange={(phone: any) => { props.setStep2Vals({ ...props.Step2Vals, customer_phone: phone }) }}
                                  //  {...formik.getFieldProps('customer_phone')}
                                  country={'tr'}

                                />
                                <div className='text-danger mt-2'>
                                  <ErrorMessage name='customer_phone' />
                                </div>
                              </div>
                            </div>
                            {/* end::Form PHONE INPUT  */}
                          </>
                          :
                          <>

                          </>}

                        {/* Soyd:: input End */}
                        <div className='col-12'>
                          <h3>{intl.formatMessage({ id: "MAIN.GENERAL.DONATION.NOTE" })}</h3>
                          <textarea
                            value={props.Step2Vals.note}
                            onChange={(note: any) => { props.setStep2Vals({ ...props.Step2Vals, note: note.target.value }) }}
                            className='form-control ' />
                        </div>
                        <div className='col-12 mt-10'>
                          <span className='ms-1 ' style={{ fontSize: '20px' }}>
                            {' '}
                            {intl.formatMessage({ id: "MAIN.GENERAL.TOTAL.DONATE" })}: <b>{currencyFormat(Math.round(totalAmount * 100) / 100)} {(genericValues?.currSym ?? '₺')}</b>
                          </span>
                        </div>
                        <div className='col-12 mt-10'>
                          <div className="d-grid gap-2">
                            <button style={{ backgroundColor: '#0E7154' }} disabled={buttonCheck} id='next' type='submit' className=' cssbuttons-io btn btn-lg text-white  me-3'>{intl.formatMessage({ id: "MAIN.CARD.BUTTON" })}</button>
                            {/* <button style={{ backgroundColor: '#0E7154' }} type='submit' className=' cssbuttons-io btn btn-lg text-white  me-3'>Bağış Yap</button> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
            </div>
          </div>
        </>)}

    </div>
  )
}

export default Step1
