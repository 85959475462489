import clsx from 'clsx'
import React, { useEffect, useRef, useState } from 'react'
import { ProgressBar } from 'react-bootstrap'
import { getProjectInfo } from '../../../core/_requests';
import { CookieBoxModel, useAuth } from '../../../../auth'
import { useIntl } from 'react-intl';
const Type1 = (prop: any) => {
  const intl = useIntl();
  const { cookieBox, saveCookieBox, genericValues, saveGenericValues } = useAuth()
  const [donateQuantity, setDonateQuantity] = useState<any>((Math.round(((prop.val.quantity ?? 0) / (genericValues?.forTL ?? 1)) * 100) / 100));
  const [donateTotal, setDonateTotal] = useState<any>(prop.val.currentTotal ?? 1);
  useEffect(() => {
    setDonateQuantity((Math.round(((prop.val.quantity ?? 0) / (genericValues?.forTL ?? 1)) * 100) / 100))
  }, [genericValues?.forTL, prop])


  const changeDonateQuantity = async (elem: any) => {
    if (Number(prop.val.pay_type) === 4) {
      let tot = (cookieBox?.obje ?? []).filter(x => x.card_id === prop.val.card_id).map(x => Number(x.quantity) * Number(x.total)).reduce((acc, val) => acc + val, 0)
      if ((Number(elem.target.value) + Number(tot) + (Math.round(((Number(prop.val.amount)) / (genericValues?.forTL ?? 1)) * 100) / 100)) > ((Math.round(((Number(prop.val.target)) / (genericValues?.forTL ?? 1)) * 100) / 100))) {

        setDonateQuantity(((Math.round(((Number(prop.val.target)) / (genericValues?.forTL ?? 1)) * 100) / 100) - (Math.round(((Number(prop.val.amount)) / (genericValues?.forTL ?? 1)) * 100) / 100) - Number(tot)))
      } else {
        setDonateQuantity(elem.target.value)
      }
    } else {
      setDonateQuantity((elem.target.value))
    }
  }
  const changeDonateTotal = async (elem: any) => {
    if (Number(prop.val.pay_type) === 5) {
      let tot = (cookieBox?.obje ?? []).filter(x => x.card_id === prop.val.card_id).map(x => Number(x.total)).reduce((acc, val) => acc + val, 0)
      if ((Number(elem.target.value) + Number(tot) + Number(prop.val.count)) > (Number(prop.val.target))) {

        setDonateTotal((Number(prop.val.target) - Number(prop.val.count) - Number(tot)))
      } else {
        setDonateTotal(elem.target.value)
      }
    } else {
      setDonateTotal(elem.target.value)
    }

  }

  const now = 40
  const ref = useRef(null)
  const month = [
    { id: 1, value: 12 },
    { id: 2, value: 18 },
    { id: 3, value: 24 },
    { id: 4, value: 36 },
  ]
  return (
    <>
      {Number(prop.val.pay_type) === 4 || Number(prop.val.pay_type) === 5 ? (

        <>

          {/* PROGRESS BAR START */}
          <div className="row">
            <div className="col-10  ">
              <div className="progress  mt-1 " style={{ height: '10px' }}>
                <div className="progress-bar"
                  style={{ width: (prop.val.percent) + '%', backgroundColor: "#0e7154" }}>
                </div>
              </div>
            </div>
            <div style={{ color: '#0e7154' }} className="col-2 text-end p-0">
              <span className='fw-bold pt-n20 fs-3'>%{`${Math.ceil(prop.val.percent)}`}</span>
            </div>
          </div>

          <div className='d-flex justify-content-between mx-3 my-1 fw-bold'>
            <span className='fs-3'>
              {Number(prop.val.pay_type) === 4 ? (Math.round(((prop.val.amount) / (genericValues?.forTL ?? 1)) * 100) / 100) : prop.val.count}
              {Number(prop.val.pay_type) === 4 ? (genericValues?.currSym ?? '₺') : ' ' + intl.formatMessage({ id: "MAIN.CARD.PIECE" })}
              <br />
              <span className='text-muted'> {intl.formatMessage({ id: "MAIN.CARD.AVAILABLE" })}</span>
              <br />
            </span>
            <span className='text-center  fs-3'>
              {Number(prop.val.pay_type) === 8 || Number(prop.val.pay_type) === 7 || Number(prop.val.pay_type) === 4 ? (Math.round(((prop.val.target) / (genericValues?.forTL ?? 1)) * 100) / 100) + (genericValues?.currSym ?? '₺') : prop.val.target + ' ' + intl.formatMessage({ id: "MAIN.CARD.PIECE" })}<br />
              <span className='text-muted'>  {intl.formatMessage({ id: "MAIN.CARD.TARGET" })}</span>
            </span>
          </div>
        </>
      ) : (
        <></>
      )}

      <div className='row mx-1'>
        <div
          className={clsx(
            'col-sm-12 p-0 ',
            {
              'col-md-6':
                Number(prop.val.pay_type) !== 1 &&
                Number(prop.val.pay_type) !== 2 &&
                Number(prop.val.pay_type) !== 4 &&
                Number(prop.val.pay_type) !== 7
              ,
            },
            {
              'col-md-12':
                Number(prop.val.pay_type) === 1 ||
                Number(prop.val.pay_type) === 2 ||
                Number(prop.val.pay_type) === 4 ||
                Number(prop.val.pay_type) === 7
            }
          )}
        >
          <div className='input-group input-group-sm mb-3'>
            <input
              style={{ borderRadius: '0px 0px 0px 15px ' }}
              autoFocus={true}
              autoComplete='off'
              min='0'
              max={((Math.round(((Number(prop.val.target)) / (genericValues?.forTL ?? 1)) * 100) / 100) - (Math.round(((Number(prop.val.amount)) / (genericValues?.forTL ?? 1)) * 100) / 100))}
              id={'donate_input_' + prop.val.card_id}
              key={`${Math.floor(Math.random() * 1000)}-min-donate-input-${prop.val.card_id}`}
              disabled={
                Number(prop.val.pay_type) === 2 ||
                Number(prop.val.pay_type) === 3 ||
                Number(prop.val.pay_type) === 5 ||
                Number(prop.val.pay_type) === 6 ||
                Number(prop.val.pay_type) === 7
              }
              ref={ref}
              //value={val.quantity}
              type='number'
              onWheel={() => (document.activeElement as HTMLElement).blur()}
              className='form-control fw-bold fs-3'
              aria-describedby='basic-addon2'
              value={donateQuantity}
              onChange={(e) => (changeDonateQuantity(e))}
            />

            <span
              style={{ borderRadius: '0px 15px 0px 0px ' }}
              className='input-group-text fs-3'
              id='basic-addon2'
            >
              {(genericValues?.currSym ?? '₺')}
            </span>
          </div>
        </div>
        {Number(prop.val.pay_type) !== 1 &&
          Number(prop.val.pay_type) !== 2 &&
          Number(prop.val.pay_type) !== 4 &&
          Number(prop.val.pay_type) !== 6 &&
          Number(prop.val.pay_type) !== 7 ? (
          <>
            <div className='col-sm-12 col-md-6 p-0'>
              <div className='input-group input-group-sm m-0 mb-3'>
                <input
                  style={{ borderRadius: '0px 0px 0px 15px  ' }}
                  autoFocus={true}
                  autoComplete='off'
                  min='0'
                  id={'donate_input_piece_' + prop.val.card_id}
                  key={`${Math.floor(Math.random() * 1000)}-min-donate-input-piece-${prop.val.card_id}`}
                  ref={ref}
                  //value={val.quantity}
                  type='number'
                  onWheel={() => (document.activeElement as HTMLElement).blur()}
                  className='form-control form-control-sm fs-3'
                  aria-describedby='basic-addon2'
                  //defaultValue={prop.val.currentTotal ?? 1}
                  value={donateTotal}
                  onChange={(e) => (changeDonateTotal(e))}
                />

                <span
                  style={{ borderRadius: '0px 15px 0px 0px ' }}
                  className='input-group-text fs-3'
                  id='basic-addon2'
                >
                  {intl.formatMessage({ id: "MAIN.CARD.PIECE" })}
                </span>
              </div>
            </div>
          </>
        ) : (
          <></>
        )}

        {Number(prop.val.pay_type) === 6 ? (
          <>
            <div className='col-sm-12 col-md-6 p-0'>
              <select
                autoComplete='off'
                className='form-select form-select-sm fs-3'
                id={'donate_input_month_' + prop.val.card_id}
                key={`${Math.floor(Math.random() * 1000)}-min`}
                ref={ref}
                aria-label="Recipient's username"
                aria-describedby='basic-addon2'
              >
                <option disabled>{intl.formatMessage({ id: "MAIN.GENERAL.SELECT" })}</option>
                {month.map((vals: any, i: any) => {
                  return (<option key={i} value={vals.value}>{vals.value + ' '}{intl.formatMessage({ id: "MAIN.CARD.MONTH" })}</option>)
                })}


              </select>
            </div>
          </>
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

export default Type1
