import {CookieBoxModel} from './_models'

const COOKIE_LOCAL_STORAGE_KEY = 'cookie-box'
const getCookieBox = (): CookieBoxModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue: string | null = localStorage.getItem(COOKIE_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const cookieBox: CookieBoxModel = JSON.parse(lsValue) as CookieBoxModel
    if (cookieBox) {
      // You can easily check auth_token expiration also
      return cookieBox
    }
  } catch (error) {
    console.error('COOKIE LOCAL STORAGE PARSE ERROR', error)
  }
}

const setCookieBox = (cookieBox: CookieBoxModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(cookieBox)
    localStorage.setItem(COOKIE_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('COOKIE LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeCookieBox = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(COOKIE_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('COOKIE LOCAL STORAGE REMOVE ERROR', error)
  }
}

export {getCookieBox, setCookieBox, removeCookieBox, COOKIE_LOCAL_STORAGE_KEY}
