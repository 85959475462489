import React, { useEffect } from 'react'
import { Button, Modal } from 'react-bootstrap';
import { useIntl } from 'react-intl';
import Lottie from 'react-lottie-player'
import lottieJson from './data.json'


function CreditPaymentModal(props: any) {

    const intl = useIntl();

    return (

        <div>
            <Modal
                fade={'true'}
                {...props}
                size="md"
                className=''
                // aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter">
                        {intl.formatMessage({ id: "MAIN.BASKET.INFO_6" })}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body className='d-inline-flex'>
                    <iframe style={{ position: 'relative', width: '100%', height: '500px' }} src={`${process.env.REACT_APP_API_URL}creditcard/test/${props.hash_id}`}></iframe>
                </Modal.Body>
                <Modal.Footer>
                    <Button onClick={props.onHide}>{intl.formatMessage({ id: "MAIN.GENERAL.CLOSE" })}</Button>
                    {/* <Button onClick={props.onSubmitPay}>İşlem Tamamdır</Button> */}
                </Modal.Footer>
            </Modal>
        </div>

    );
}

export default CreditPaymentModal