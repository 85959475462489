import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import clsx from 'clsx'
import { Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import { VerifyCode, NewPasswordChange, ChangeControl } from '../core/_requests'
import PhoneInput from 'react-phone-input-2'
import { toAbsoluteUrl } from '../../../../_metronic/helpers'
const initialValues = {
  newpassword: '',
  confirmpassword: ''
}

const changePasswordSchema = Yup.object().shape({
  newpassword: Yup.string()
    .min(8, 'En az 8 karakter olmalı')
    .required('Şifre zorunlu'),
  confirmpassword: Yup.string()
    .required('Şifre tekrar zorunlu alan')
    .when('newpassword', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('newpassword')], "Şifreler eşleşmiyor"),
    }),

})

export function ChangePassword() {
  let { id } = useParams();
  const [phoneNumber, setPhoneNumber] = useState()
  const [loading, setLoading] = useState(false)
  const [hasErrors, setHasErrors] = useState<boolean | undefined>(undefined)
  const [checkVerify, setCheckVerify] = useState(false);
  const [verifyVal, setVerifyVal] = useState<any>();

  useEffect(() => {
    ChangeControl(id).then((res: any) => {
      if (res.data.islem !== 'basarili') {
        window.location.href = `${process.env.REACT_APP_ANASAYFA}auth`;
      }
    });
  }, [])
  const verifyControl = (val: any) => {
    VerifyCode(val, id)
      .then(({ data: result }: any) => {
        setLoading(true)
        if (result['islem'] === 'basarili') {
          setHasErrors(false)
          setLoading(false)
          setCheckVerify(true)
        }
        else {
          setCheckVerify(false)
          alert('Doğrulama Kodu Hatalı. Tekrar Deneyin')
        }
      })
      .catch(() => {
        setHasErrors(true)
        setLoading(false)
      })
  }
  const formik = useFormik({
    initialValues,
    validationSchema: changePasswordSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setLoading(true)
      setHasErrors(undefined)
      setTimeout(() => {
        NewPasswordChange(values.newpassword, verifyVal, id)
          .then(({ data: result }: any) => {
            setLoading(true)
            if (result['islem'] === 'basarili') {
              setHasErrors(false)
              setLoading(false)
              setCheckVerify(true)
              window.location.href = `${process.env.REACT_APP_ANASAYFA}auth`;
            }
            else {
              alert('Hatalı işlem...')
            }
          })
          .catch(() => {
            setHasErrors(true)
            setLoading(false)
            setSubmitting(false)
            setStatus('The login detail is incorrect')
          })
      }, 1000)
    },
  })

  return (
    <div >
      <div className='container mt-20'>
        <div className='text-center mb-10'>
          {/* begin::Title */}
          <h1 className='text-dark mb-3'>Şifre Sıfırla </h1>
        </div>
        {/* begin::Form group EMAİL*/}
        <div className='fv-row mb-10'>
          <label className='form-label fw-bolder text-gray-900 fs-6'>Doğrulama Kodu</label>
          <input
            type='text'
            placeholder=''
            autoComplete='off'
            onChange={(e: any) => setVerifyVal(e.target.value)}
            className={clsx(
              'form-control form-control-lg form-control-solid')}
          />
        </div>
        {/* begin::Form group */}
        <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
          <button
            type='submit'
            id='kt_password_reset_submit'
            className='btn btn-lg btn-primary fw-bolder me-4'
            onClick={() => verifyControl(verifyVal)}
          >
            <span className='indicator-label'>Doğrula</span>
            {loading && (
              <span className='indicator-progress'>
                Lütfen Bekleyin...
                <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
              </span>
            )}
          </button>
        </div>
        {/* end::Form group */}
      </div>
      {checkVerify === true ? <><form
        className='form w-100 fv-plugins-bootstrap5 fv-plugins-framework'
        noValidate
        id='kt_login_password_reset_form'
        onSubmit={formik.handleSubmit}
      >
        <div className='container mt-20'>
          <div className='text-center mb-10'>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Şifre Sıfırla </h1>
          </div>

          <div className="row">
            <div className='mb-10 col-sm-12  col-md-6' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bolder text-dark fs-6'>Şifre</label>
                <div className='position-relative mb-3'>
                  <input
                    type='password'
                    placeholder='Şifre'
                    autoComplete='off'
                    {...formik.getFieldProps('newpassword')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.newpassword && formik.errors.newpassword,
                      },
                      {
                        'is-valid': formik.touched.newpassword && !formik.errors.newpassword,
                      }
                    )}
                  />
                  {formik.touched.newpassword && formik.errors.newpassword && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block text-dark'>
                        <span role='alert'>{formik.errors.newpassword}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* begin::Meter */}
                <div
                  className='d-flex align-items-center mb-3'
                  data-kt-password-meter-control='highlight'
                >
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
                {/* end::Meter */}
              </div>
              <div className='text-dark fw-bold'>
                En az 8 karakter olması tavsiye edilir.

              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='col-sm-12 col-md-6 mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>Şifre Tekrar </label>
              <input
                type='password'
                placeholder='Şifre Tekrar'
                autoComplete='off'
                {...formik.getFieldProps('confirmpassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.confirmpassword && formik.errors.confirmpassword,
                  },
                  {
                    'is-valid': formik.touched.confirmpassword && !formik.errors.confirmpassword,
                  }
                )}
              />
              {formik.touched.confirmpassword && formik.errors.confirmpassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.confirmpassword}</span>
                  </div>
                </div>
              )}
            </div>
          </div>



          {/* begin::Form group */}
          <div className='d-flex flex-wrap justify-content-center pb-lg-0'>
            <button
              type='submit'
              id='kt_password_reset_submit'
              className='btn btn-lg btn-primary fw-bolder me-4'
              disabled={formik.errors.confirmpassword ? formik.isSubmitting || !formik.isValid : false}
            >
              <span className='indicator-label'>Şifreyi Güncelle</span>
              {loading && (
                <span className='indicator-progress'>
                  Lütfen Bekleyin...
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

          </div>

        </div>
      </form>
      </> : <></>}

    </div>
  )
}
