/**
 * High level router.
 *
 * Note: It's recommended to compose related routes in internal router
 * components (e.g: `src/app/modules/Auth/pages/AuthPage`, `src/app/BasePage`).
 */

import { FC } from 'react'
import { Routes, Route, BrowserRouter, Navigate } from 'react-router-dom'
import { PrivateRoutes } from './PrivateRoutes'
import { ErrorsPage } from '../modules/errors/ErrorsPage'
import { Logout, AuthPage, useAuth } from '../modules/auth'
import { App } from '../App'
import MainPage from '../modules/guest/MainPage'
import { Registration } from '../modules/auth/components/Registration'
import { ForgotPassword } from './../modules/auth/components/ForgotPassword';
import { ChangePassword } from '../modules/auth/components/ChangePassword'

/**
 * Base URL of the website.
 *
 * @see https://facebook.github.io/create-react-app/docs/using-the-public-folder
 */
const { PUBLIC_URL } = process.env

const AppRoutes: FC = () => {
  const { currentUser } = useAuth()
  return (
    <BrowserRouter basename={PUBLIC_URL}>
      <Routes>
        <Route element={<App />}>
          <Route path='error/*' element={<ErrorsPage />} />
          <Route path='logout' element={<Logout />} />

          {currentUser ? (
            <>
              <Route path='*' element={<MainPage />} />
              {currentUser.authority === '1' ? (
                <>
                  <Route path='auth' element={<Navigate to='/admin' />} />
                  <Route index={false} path='admin/*' element={<PrivateRoutes />} />
                </>
              ) : (
                <>
                  <Route path='auth' element={<Navigate to='/user' />} />
                  <Route index={false} path='user/*' element={<PrivateRoutes />} />
                </>
              )}

            </>
          ) : (
            <>
              <Route path='registration/' element={<Registration />} />
              <Route path='forgotpassword/' element={<ForgotPassword />} />
              <Route path='changepassword/' element={<ChangePassword />} />
              <Route path='auth/*' element={<AuthPage />} />
              <Route path='*' element={<MainPage />} />
            </>
          )}
        </Route>
      </Routes>
    </BrowserRouter>
  )
}

export { AppRoutes }
