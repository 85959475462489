/* eslint-disable jsx-a11y/anchor-is-valid */
import { FC, useEffect, useRef, useState } from 'react'
import clsx from 'clsx'
import {
  toAbsoluteUrl,
  defaultMessages,
  defaultUserInfos,
  MessageModel,
  UserInfoModel,
  messageFromClient,
} from '../../helpers'
import { addCustomerMessage, getCustomerMessages } from '../../../app/modules/apps/donation-customer-tracking/customer-detail-page/core/_requests'
import { useAuth } from '../../../app/modules/auth/core/Auth'
import Swal from 'sweetalert2'

type Props = {
  isDrawer?: boolean
  id?: number
}

const bufferMessages = defaultMessages

const ChatInner: FC<Props> = ({ isDrawer = false, id = 0 }) => {
  const { currentUser, logout } = useAuth()
  const [chatUpdateFlag, toggleChatUpdateFlat] = useState<boolean>(false)
  const [message, setMessage] = useState<string>('')
  const [messages, setMessages] = useState<any>([])
  const [userInfos] = useState<UserInfoModel[]>(defaultUserInfos)
  const getMessages = async (id: any) => {
    await getCustomerMessages(id).then((deger: any) => {
      if (deger === undefined) {
        setMessages([]);
      } else {
        setMessages(deger);

      }
    })

  }
  const sendMessage = async () => {
    const today = new Date();
    //let cr_date = today.getFullYear +"-"+(today.getMonth+1)+"-"+
    const newMessage = {
      icon: currentUser?.icon,
      user_id: currentUser?.id,
      text: message,
      cr_date: today,
      adi: "Siz",
      soyadi: ""
    }
    await addCustomerMessage({ message: message, customer_id: id }).then((deger: any) => {
      if (deger === true) {
        setMessages((current: any) => [...current, newMessage])

      } else {
        Swal.fire({
          title: 'Hata',
          text: 'Bir hata oluştu.',
          icon: 'error',
          showConfirmButton: false,
          timer: 2000,
        })
      }
    })
    //bufferMessages.push(newMessage)
    toggleChatUpdateFlat(!chatUpdateFlag)
    setMessage('')

  }

  const onEnterPress = (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault()
      sendMessage()
    }
  }
  const messagesEndRef = useRef<null | HTMLDivElement>(null);
  const scrollToBottom = () => {
    const domNode = messagesEndRef.current;
    if (domNode) {
      domNode.scrollTop = domNode.scrollHeight;
    }
  }

  useEffect(() => {
    getMessages(id)
  }, [])

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  function time_ago(time: any, date: string) {

    switch (typeof time) {
      case 'number':
        break;
      case 'string':
        time = +new Date(time);
        break;
      case 'object':
        if (time.constructor === Date) time = time.getTime();
        break;
      default:
        time = +new Date();
    }
    var time_formats = [
      [60, 'Saniye', 1], // 60
      [120, '1 Dakika önce', '1 Dakika sonra'], // 60*2
      [3600, 'Dakika', 60], // 60*60, 60
      [7200, '1 Saat Önce', '1 Saat Sonra'], // 60*60*2
      [86400, 'Saat', 3600], // 60*60*24, 60*60
      [172800, 'Dün', 'Yarın'], // 60*60*24*2
      [604800, 'Gün', 86400], // 60*60*24*7, 60*60*24

    ];
    var seconds = (+new Date() - time) / 1000,
      token = 'önce',
      list_choice = 1;
    if (seconds < 1) {
      return 'Şimdi'
    }
    if (seconds < 0) {
      seconds = Math.abs(seconds);
      token = 'Birazdan';
      list_choice = 2;
    }
    var i = 0,
      format;
    while (format = time_formats[i++])
      if (Number(seconds) < Number(format[0])) {
        if (typeof format[2] == 'string')
          return format[list_choice];
        else
          return Math.floor(seconds / format[2]) + ' ' + format[1] + ' ' + token;
      }
    return date;
  }
  return (
    <div
      className='card-body'
      id={isDrawer ? 'kt_drawer_chat_messenger_body' : 'kt_chat_messenger_body'}
    >
      <div
        ref={messagesEndRef}
        className={clsx('scroll-y me-n5 pe-5', { 'h-300px h-lg-auto': !isDrawer })}
        data-kt-element='messages'
        data-kt-scroll='true'
        data-kt-scroll-top="false"
        data-kt-scroll-activate='{default: false, lg: true}'
        data-kt-scroll-max-height='auto'
        data-kt-scroll-dependencies={
          isDrawer
            ? '#kt_drawer_chat_messenger_header, #kt_drawer_chat_messenger_footer'
            : '#kt_header, #kt_toolbar, #kt_footer, #kt_chat_messenger_header, #kt_chat_messenger_footer'
        }
        data-kt-scroll-wrappers={
          isDrawer ? '#kt_drawer_chat_messenger_body' : '#kt_content, #kt_chat_messenger_body'
        }
        data-kt-scroll-offset={isDrawer ? '0px' : '-2px'}
      >
        {(messages !== null && messages !== undefined) ? <>
          {messages.map((message: any, index: number) => {
            const state = message.user_id !== currentUser?.id ? 'info' : 'primary'

            const contentClass = `${isDrawer ? '' : 'd-flex'} justify-content-${message.user_id !== currentUser?.id ? 'start' : 'end'
              } mb-10`
            return (
              <div
                key={`message${index}`}
                className={clsx('d-flex', contentClass, 'mb-10', { 'd-none': message.template })}
              >
                <div
                  className={clsx(
                    'd-flex flex-column align-items',
                    `align-items-${message.user_id !== currentUser?.id ? 'start' : 'end'}`
                  )}
                >
                  <div className='d-flex align-items-center mb-2'>
                    {message.user_id !== currentUser?.id ? (
                      <>
                        <div className='symbol  symbol-35px symbol-circle '>
                          {
                            message.icon === ''
                              ?
                              <>
                                <i className="fa-solid fa-user fs-2x"></i>
                              </>
                              :
                              <>
                                <img src={`${process.env.REACT_APP_MEDIA_API_URL}images/${message.icon}`} onError={(e: any) => {
                                  e.target.onerror = null;
                                  e.target.src = `${toAbsoluteUrl('/media/avatars/blank.png')}`
                                }} alt='customer' />
                              </>
                          }
                        </div>
                        <div className='ms-3'>
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary me-1'
                          >
                            {message.adi + " " + message.soyadi}
                          </a>
                          <span className='text-muted fs-7 mb-1'>{time_ago(new Date(message.cr_date), message.cr_date)}</span>
                        </div>
                      </>
                    ) : (
                      <>
                        <div className='me-3'>
                          <span className='text-muted fs-7 mb-1'>{time_ago(new Date(message.cr_date), message.cr_date)}</span>
                          <a
                            href='#'
                            className='fs-5 fw-bolder text-gray-900 text-hover-primary ms-1'
                          >
                            Siz
                          </a>
                        </div>
                        <div className='symbol  symbol-35px symbol-circle '>
                          {
                            message.icon === ''
                              ?
                              <>
                                <i className="fa-solid fa-user fs-2x"></i>
                              </>
                              :
                              <>
                                <img src={`${process.env.REACT_APP_MEDIA_API_URL}images/${message.icon}`} onError={(e: any) => {
                                  e.target.onerror = null;
                                  e.target.src = `${toAbsoluteUrl('/media/avatars/blank.png')}`
                                }} alt='customer' />
                              </>
                          }

                        </div>
                      </>
                    )}
                  </div>

                  <div
                    className={clsx(
                      'p-5 rounded',
                      `bg-light-${state}`,
                      'text-dark fw-bold mw-lg-400px',
                      `text-${message.user_id !== currentUser?.id ? 'start' : 'end'}`
                    )}
                    data-kt-element='message-text'
                    dangerouslySetInnerHTML={{ __html: message.text }}
                  ></div>
                </div>
              </div>
            )
          })}
        </> : <></>}

      </div>

      <div
        className='card-footer pt-4 px-0'
        id={isDrawer ? 'kt_drawer_chat_messenger_footer' : 'kt_chat_messenger_footer'}
      >
        <textarea
          className='form-control form-control-flush mb-3 border'
          rows={2}
          data-kt-element='input'
          placeholder='Not yaz...'
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyDown={onEnterPress}
        ></textarea>

        <div className='d-flex flex-stack'>
          <div className='d-flex align-items-center me-2'>

          </div>
          <button
            className='btn btn-primary'
            type='button'
            data-kt-element='send'
            onClick={sendMessage}
          >
            Not Ekle
          </button>
        </div>
      </div>
    </div>
  )
}

export { ChatInner }
