/* eslint-disable jsx-a11y/anchor-is-valid */
import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import clsx from 'clsx'
import { getUserByToken, register } from '../core/_requests'
import { useAuth } from '../core/Auth'
import { KTSVG, toAbsoluteUrl } from '../../../../_metronic/helpers'
import { PasswordMeterComponent } from '../../../../_metronic/assets/ts/components'
import "yup-phone";
import 'react-phone-input-2/lib/bootstrap.css'
import PhoneInput from 'react-phone-input-2'
import Swal from 'sweetalert2';
const initialValues = {
  firstname: '',
  lastname: '',
  email: '',
  phone: '',
  password: '',
  changepassword: '',
  acceptTerms: false,
}

const registrationSchema = Yup.object().shape({
  firstname: Yup.string()
    .min(3, 'En az 3 karakter olmalı')
    .max(50, 'En fazla 50 karakter')
    .required('İsim zorunlu alan'),
  lastname: Yup.string()
    .min(3, 'En az 3 karakter olmalı')
    .max(50, 'En fazla 50 karakter')
    .required('Soyisim zorunlu alan'),
  phone: Yup.string()
    .min(10, 'En az 10 karakter olmalı')
    .max(15, 'En fazla 15 karakter')
    .required('Soyisim zorunlu alan'),
  password: Yup.string()
    .min(8, 'En az 8 karakter olmalı')
    .required('Şifre zorunlu'),

  // phone: Yup.string()
  //   //@ts-ignore
  //   .phone("TR", true, 'Türkiye standartlarına uygun bir numara giriniz')
  //   .required('Telefon zorunlu'),
  changepassword: Yup.string()
    .required('Şifre tekrar zorunlu alan')
    .when('password', {
      is: (val: string) => (val && val.length > 0 ? true : false),
      then: Yup.string().oneOf([Yup.ref('password')], "Şifreler eşleşmiyor"),
    }),
  acceptTerms: Yup.bool().required('sözleşmeyi kabul etmelisiniz'),
})

export function Registration() {
  const [loading, setLoading] = useState(false)
  const { saveAuth, setCurrentUser } = useAuth()
  const formik = useFormik({
    initialValues,
    validationSchema: registrationSchema,
    onSubmit: async (values, { setStatus, setSubmitting }) => {

      setLoading(true)

      try {

        const { data: auth } = await register(values)
        if (auth.islem === 'tel_var') {
          Swal.fire({
            title: 'İşlem Hatası',
            text: 'Böyle bir Telefon Numarası   Mevcut !',
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500,
          })
          setLoading(false)
        }
        else if (auth.islem === 'mail_var') {
          Swal.fire({
            title: 'İşlem Hatası',
            text: 'Böyle bir Mail Adresi  Mevcut !',
            icon: 'warning',
            showConfirmButton: false,
            timer: 1500,
          })
          setLoading(false)
        } else {
          window.location.href = '/auth'
          saveAuth(auth)
          const { data: user } = await getUserByToken(auth.api_token)
          setCurrentUser(user)
        }

      } catch (error) {
        console.error(error)
        saveAuth(undefined)
        setStatus('The registration details is incorrect')
        setSubmitting(false)
        setLoading(false)
      }
    },
  })

  useEffect(() => {
    PasswordMeterComponent.bootstrap()
  }, [])
  const [phoneNumber, setPhoneNumber] = useState()
  return (
    <div
      className='d-flex flex-column flex-column-fluid   bgi-no-repeat bgi-size-contain  '
      style={{
        backgroundImage: `url(${toAbsoluteUrl('/media/illustrations/sketchy-1/donate.jpg')})`, backgroundPosition: 'center', backgroundRepeat: 'no-repeat', backgroundSize: 'cover',
      }}
    >
      <div className='w-lg-700px bg-body rounded shadow-sm p-10  mx-auto mt-15'>

        <form
          className='form w-100  '
          noValidate
          id='kt_login_signup_form'
          onSubmit={formik.handleSubmit}
        >
          {/* begin::Heading */}
          <div className='mb-10 text-center'>
            <img
              alt='Logo'
              src={toAbsoluteUrl('/media/logos/bereket-logo.png')}
              className='theme-light-show h-45px'
            ></img>
            {/* <h1 className='text-dark mb-3'>Sisteme Giriş Yap</h1> */}
            <div className='text-gray-400 fw-bold fs-4'><br />
              {/* New Here?{' '}
          <Link to='/auth/registration' className='link-primary fw-bolder'>
            Create an Account
          </Link> */}
            </div>
            {/* begin::Title */}
            <h1 className='text-dark mb-3'>Hesap Oluştur</h1>
            {/* end::Title */}
          </div>
          {/* end::Heading */}

          {formik.status && (
            <div className='mb-lg-15 alert alert-danger'>
              <div className='alert-text font-weight-bold'>{formik.status}</div>
            </div>
          )}

          {/* begin::Form group Firstname */}
          <div className='row mb-1'>
            <div className="col-sm-12 col-xl-6">
              <label className='form-label fw-bolder text-dark fs-6'>İsim</label>
              <input
                placeholder='isim giriniz'
                type='text'
                autoComplete='off'
                {...formik.getFieldProps('firstname')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.firstname && formik.errors.firstname,
                  },
                  {
                    'is-valid': formik.touched.firstname && !formik.errors.firstname,
                  }
                )}
              />
              {formik.touched.firstname && formik.errors.firstname && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.firstname}</span>
                  </div>
                </div>
              )}
            </div>
            <div className='col-sm-12 col-xl-6'>
              {/* begin::Form group Lastname */}
              <div className='fv-row mb-5'>
                <label className='form-label fw-bolder text-dark fs-6'>Soyisim</label>
                <input
                  placeholder='Soyisim'
                  type='text'
                  autoComplete='off'
                  {...formik.getFieldProps('lastname')}
                  className={clsx(
                    'form-control form-control-lg form-control-solid',
                    {
                      'is-invalid': formik.touched.lastname && formik.errors.lastname,
                    },
                    {
                      'is-valid': formik.touched.lastname && !formik.errors.lastname,
                    }
                  )}
                />
                {formik.touched.lastname && formik.errors.lastname && (
                  <div className='fv-plugins-message-container'>
                    <div className='fv-help-block'>
                      <span role='alert'>{formik.errors.lastname}</span>
                    </div>
                  </div>
                )}
              </div>
              {/* end::Form group */}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group Email */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>E-posta Adresi</label>
            <input
              placeholder='Email'
              type='email'
              autoComplete='off'
              {...formik.getFieldProps('email')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.email && formik.errors.email },
                {
                  'is-valid': formik.touched.email && !formik.errors.email,
                }
              )}
            />
            {formik.touched.email && formik.errors.email && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.email}</span>
                </div>
              </div>
            )}
          </div>
          {/* end::Form group */}
          <div className='fv-row mb-7'>
            <label className='form-label fw-bolder text-dark fs-6'>Telefon Numarası</label>
            <PhoneInput
              inputStyle={{ width: '100%' }}
              enableSearch
              inputProps={{
                name: 'phone',
                required: true,
                autoFocus: true
              }}
              {...formik.getFieldProps('phone')}
              country={'tr'}
              value={phoneNumber}
              onChange={(phone: any) => formik.values.phone = phone}

            />
            {/* <input
              placeholder='Telefon Numarası'
              type='tel'
              autoComplete='off'
              {...formik.getFieldProps('phone')}
              className={clsx(
                'form-control form-control-lg form-control-solid',
                { 'is-invalid': formik.touched.phone && formik.errors.phone },
                {
                  'is-valid': formik.touched.phone && !formik.errors.phone,
                }
              )}
            /> */}
            {/* {formik.touched.phone && formik.errors.phone && (
              <div className='fv-plugins-message-container'>
                <div className='fv-help-block'>
                  <span role='alert'>{formik.errors.phone}</span>
                </div>
              </div>
            )} */}
          </div>
          {/* begin::Form group Password */}
          <div className="row">
            <div className='mb-10 col-sm-12  col-md-6' data-kt-password-meter='true'>
              <div className='mb-1'>
                <label className='form-label fw-bolder text-dark fs-6'>Şifre</label>
                <div className='position-relative mb-3'>
                  <input
                    type='password'
                    placeholder='Şifre'
                    autoComplete='off'
                    {...formik.getFieldProps('password')}
                    className={clsx(
                      'form-control form-control-lg form-control-solid',
                      {
                        'is-invalid': formik.touched.password && formik.errors.password,
                      },
                      {
                        'is-valid': formik.touched.password && !formik.errors.password,
                      }
                    )}
                  />
                  {formik.touched.password && formik.errors.password && (
                    <div className='fv-plugins-message-container'>
                      <div className='fv-help-block'>
                        <span role='alert'>{formik.errors.password}</span>
                      </div>
                    </div>
                  )}
                </div>
                {/* begin::Meter */}
                <div
                  className='d-flex align-items-center mb-3'
                  data-kt-password-meter-control='highlight'
                >
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px me-2'></div>
                  <div className='flex-grow-1 bg-secondary bg-active-success rounded h-5px'></div>
                </div>
                {/* end::Meter */}
              </div>
              <div className='text-muted'>
                En az 8 karakter olması tavsiye edilir.

              </div>
            </div>
            {/* end::Form group */}

            {/* begin::Form group Confirm password */}
            <div className='col-sm-12 col-md-6 mb-5'>
              <label className='form-label fw-bolder text-dark fs-6'>Şifre Tekrar </label>
              <input
                type='password'
                placeholder='Şifre Tekrar'
                autoComplete='off'
                {...formik.getFieldProps('changepassword')}
                className={clsx(
                  'form-control form-control-lg form-control-solid',
                  {
                    'is-invalid': formik.touched.changepassword && formik.errors.changepassword,
                  },
                  {
                    'is-valid': formik.touched.changepassword && !formik.errors.changepassword,
                  }
                )}
              />
              {formik.touched.changepassword && formik.errors.changepassword && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.changepassword}</span>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='fv-row mb-10'>
            <div className='form-check form-check-custom form-check-solid'>
              <input
                className='form-check-input'
                type='checkbox'
                id='kt_login_toc_agree'
                {...formik.getFieldProps('acceptTerms')}
              />
              <label
                className='form-check-label fw-bold text-gray-700 fs-6'
                htmlFor='kt_login_toc_agree'
              >
                Kullanıcı{' '}
                <span
                  className='fw-bold text-dark hover pointer-event'
                  style={{ cursor: 'pointer' }}
                  data-bs-toggle="modal"
                  data-bs-target="#kt_modal_1">Sözleşmesini</span>{' '}
                kabul ediyorum.
              </label>
              {formik.touched.acceptTerms && formik.errors.acceptTerms && (
                <div className='fv-plugins-message-container'>
                  <div className='fv-help-block'>
                    <span role='alert'>{formik.errors.acceptTerms}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="modal fade" tabIndex={-1} id="kt_modal_1">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h5 className="modal-title">Sözleşme</h5>
                    <div
                      className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                      data-bs-dismiss="modal"
                      aria-label="Close"
                    >
                      <KTSVG
                        path="/media/icons/duotune/arrows/arr061.svg"
                        className="svg-icon svg-icon-2x"
                      />
                    </div>
                  </div>
                  <div className="modal-body">
                    <p>Rabbimize sadakatimizin simgesi, Hz. İbrahim’in fedakarlığının hatırası Allah’a yakınlaşma vesilesi anlamına gelen kurban, birçok güzelliği ve manayı içerisinde barındırması sebebiyle son derece önemli bir ibadettir.  Kurban olur insan, kestiği/kestirdiği kurbanlar ile. Ulaşır takvası ve niyeti ameliyle beraber. Yaklaşır Allah’a, yakınlaşır kardeşlerine onları görmese bile.

                      İbadet şuuru ve emanet bilinciyle;
                      Keseceğiniz adak kurbanı, vacip kurban, akika kurbanı, şükür kurbanı, hacet kurbanı ve şifa kurbanlarınızı başta Afrika ve Asya olmak üzere 3 kıta 23 ülkede yokluk ve açlıkla mücadele eden ihtiyaç sahiplerine  veteriner hekimler ve kendi personeli nezaretinde dini hassasiyetleri titizlikle gözeterek Bereket Derneği vekaleten vacip kurban bağışı kampanyası düzenlemektedir.

                      “Biz, her ümmete -(Kurban kesmeye uygun) hayvan cinsinden kendilerine rızık olarak verdiklerimiz üzerine Allah’ın adını ansınlar diye- kurban kesmeyi gerekli kıldık. İmdi, İlahınız, bir tek İlah’tır. Öyle ise, O’na teslim olun. (Ey Muhammed!) O ihlaslı ve mütevazı insanları müjdele!” (Hac 22/34).</p>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-light"
                      data-bs-dismiss="modal"
                    >
                      Kapat
                    </button>
                  </div>
                </div>
              </div>
            </div>


          </div>
          {/* end::Form group */}

          {/* begin::Form group */}
          <div className='text-center'>

            <button
              type='submit'
              id='kt_sign_up_submit'
              className='btn btn-lg btn-primary w-100 mb-5'
              disabled={formik.isSubmitting || !formik.isValid || !formik.values.acceptTerms}
            >
              {!loading && <span className='indicator-label'>Kayıt Ol</span>}
              {loading && (
                <span className='indicator-progress' style={{ display: 'block' }}>
                  Lütfen Bekleyiniz...{' '}
                  <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
                </span>
              )}
            </button>

            <Link to='/auth/login'>
              <button
                type='button'
                id='kt_login_signup_form_cancel_button'
                className='btn btn-lg btn-light-primary w-100 mb-5'
              >
                Giriş Sayfası
              </button>
            </Link>
          </div>
          {/* end::Form group */}
        </form>
      </div>
    </div>
  )
}
