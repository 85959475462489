const QUERIES = {
  USERS_LIST: 'users-list',
  CATEGORIES_LIST: 'categories-list',
  DONATIONS_LIST: 'donations-list',
  DONATIONS_MEDIA_LIST: 'donations-media-list',
  CUSTOMER_LIST:'customer-list',
  TRACKING_LIST:'tracking-list',
  PAY_CUSTOMER_LIST:'pay-list',
  DONATIONS_USER_LIST:'donate-user-management',
  DONATIONS_USER_LIST_DONATE:'donate-user-management-info',
  DONATE_AUTO_LIST:'donate-auto_list',
  DONATE_STUDENT_LIST:'donate-student_list',
  TASKS_LIST:'tasks_list',
  STUDENTS_LIST:'students_list',
  DONATIONS_DETAIL_LIST:'donations-detail-list'

}

export {QUERIES}
