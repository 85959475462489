import axios, {AxiosResponse} from 'axios'

const API_URL = process.env.REACT_APP_DATABASE_API_URL
const DONATION_URL = `${API_URL}donations`
const GET_USERS_URL = `${API_URL}/donations/donations_list`

const getChartValues = (id: any) => {
  const all = axios
    .post(`${DONATION_URL}/donations_month/${id}`)
    .then((d: AxiosResponse<any>) => d.data)
  return all
}
const getCategory = () => {
  const all = axios
    .post(`${API_URL}/customer/get_categories`)
    .then((d: AxiosResponse<any>) => d.data)
  return all
}

export {getChartValues, getCategory}
