import { EnableSidebar, PageTitle } from '../../../_metronic/layout/core'
import {
  ListsWidget4,
  ListsWidget5,
  TablesWidget9,
  MixedWidget13,
  MixedWidget14,
  MixedWidget15,
} from '../../../_metronic/partials/widgets'
import { useAuth } from '../../modules/auth/core/Auth'
import { useState, useEffect } from 'react';
import { getUserMainInfo, getAdminMainInfo } from './_requests';
import DonationsPage from '../../modules/apps/user-donation-management/DonationsPage';
import { getChartValues } from '../../../_metronic/partials/core/_requests';
import ChartView from './components/MoneyChart';
import { useIntl } from 'react-intl';

const DashboardPage = () => {

  function currencyFormat(x: any) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".");
  }

  const intl = useIntl();
  const { currentUser, logout, genericValues } = useAuth();
  const [langData, setLangData] = useState<any>(genericValues?.langJson)
  const [mainValues, setMainValues] = useState<any>();
  const [mainAdminValues, setMainAdminValues] = useState<any>();
  const [chartmonth, setChartMonth] = useState([]);
  useEffect(() => {
    setLangData(genericValues?.langJson)
  }, [genericValues])
  let degergelen: any[] = [];

  const UserInfo = async () => {
    const deger = await getUserMainInfo()

    setMainValues(deger);
  }
  const AdminInfo = async () => {
    const val = await getAdminMainInfo();
    setMainAdminValues(val);

  }
  const getChartVal = async () => {
    const deger = await getChartValues(0);
    Object.values(deger).forEach((element: any) => {
      degergelen.push(element.amount)
    });


  }
  useEffect(() => {
    getChartVal();
    console.log("mainAdminValues?.last_donates", mainAdminValues?.last_donates)
  }, [])

  useEffect(() => {
    UserInfo();
    AdminInfo();
  }, [])

  return (
    <>
      <div className='row gy-5 g-xl-10'>
        <div className='col-xl-9'>
          {currentUser?.authority === '1' ?
            <>
              <ul className="nav nav-tabs nav-line-tabs mb-5 fs-6">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_1"
                  >
                    <b>Son Bağışlar Listesi</b>
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-bs-toggle="tab"
                    href="#kt_tab_pane_2"
                  >
                    <b>Bağış Grafiği</b>
                  </a>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade active show"
                  id="kt_tab_pane_1"
                  role="tabpanel"
                >
                  <div className="card">
                    <div className="card-header border-0 pt-6">
                      <div className="card-title">
                        <div className="d-flex align-items-center position-relative my-1"><b>Son Bağışlar Listesi</b></div>
                      </div>
                    </div>
                    <div className="card-body py-4">
                      <div className="table-responsive">
                        <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                          <thead>
                            <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                              <th>BAĞIŞ ADI</th>
                              <th>KULLANICI BILGI</th>
                              <th>BAĞIŞ TARIH</th>
                              <th>FIYAT x ADET</th>
                              <th>TOPLAM</th>
                              <th>ÖDEME ŞEKLİ</th>
                              <th>ÜYE DURUM</th>
                              <th>OTOMATIK ÖDEME</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mainAdminValues?.last_donates.map((res: any, i: any) => {
                              const [year, month, day] = res.cr_date?.split('-');
                              day?.split(' ')

                              const result = [day?.split(' ')[0], month, year,].join('/');
                              return (
                                <tr className='fw-bold text-gray-600' key={i}>
                                  <td>{res.name ?? '-'} </td>
                                  <td>{res.user_name}<br></br>{res.user_phone}</td>
                                  <td>{result}</td>
                                  <td>{currencyFormat(res.quantity) + res.currSym + ' x ' + res.total}</td>
                                  <td>{currencyFormat(res.total_amount) + res.currSym} </td>
                                  <td className='text-start'>{intl.formatMessage({ id: "USER.PAYMENT.TYPE_" + res.pay_type })}</td>
                                  <td className='text-center'>{res.user_control != 0 ? <span className='badge badge-success'>Üye</span> : <span className='badge badge-danger'>Üye Değil</span>}</td>
                                  <td className='text-center'>{res.autopay != 0 ? <span className='badge badge-success'>Var</span> : <span className='badge badge-danger'>Yok</span>}</td>
                                </tr>)
                            })}

                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane fade" id="kt_tab_pane_2" role="tabpanel">
                  <div className="col-sm-12 col-xxl-12 text-center mt-5">
                    <label className='fw-bold h2 mb-10'>Bağışlar Grafiği</label>
                    <ChartView data={degergelen} />
                  </div>
                </div>

              </div>


            </>
            :
            <>
              <div className="card">
                <div className="card-header d-block border-0 pt-6">
                  <div className="card-title ">
                    <div className="d-flex text-center align-items-center  my-1"><b>{intl.formatMessage({ id: "USER.GENERAL.LAST.DONATE.LIST" })}</b></div>
                  </div>
                </div>
                <div className="card-body py-4">
                  <div className="table-responsive">
                    <table className='table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer'>
                      <thead>
                        <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
                          <th>{intl.formatMessage({ id: "USER.GENERAL.DONATE.NAME" })}</th>
                          <th>{intl.formatMessage({ id: "USER.GENERAL.PIECE" })}</th>
                          <th>{intl.formatMessage({ id: "USER.GENERAL.TOTAL.AMOUNT" })}</th>
                          <th>{intl.formatMessage({ id: "USER.GENERAL.DATE" })}</th>
                          <th>{intl.formatMessage({ id: "USER.GENERAL.PAY.TYPE" })}</th>
                          <th>{intl.formatMessage({ id: "USER.GENERAL.STATU" })}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {mainValues?.last_donates.map((res: any, i: any) => {
                          const [year, month, day] = res.cr_date?.split('-');
                          day?.split(' ')
                          const result = [day?.split(' ')[0], month, year,].join('/');
                          return (
                            <tr className='fw-bold text-gray-600' key={i}>
                              <td>{langData["DB.DONATE_LIST.VALUE_" + res.card_id]}</td>
                              <td>{res.total ?? '-'}</td>
                              <td>{res.total_amount ?? '-'} {genericValues?.currSym}</td>
                              <td>{result}</td>
                              <td className='text-start'>{intl.formatMessage({ id: "USER.PAYMENT.TYPE_" + res.pay_type })}</td>
                              <td>{intl.formatMessage({ id: "USER.PAYMENT.STATUS_" + res.status })} </td>
                            </tr>)
                        })}

                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </>}
        </div>
        <div className='col-xl-3'>
          <div className='row'>
            <div className='col-xl-12'>

              <MixedWidget13
                degerss={degergelen}
                className='card-xl-stretch mb-xl-10'
                backGroundColor='#008574'
                chartHeight='100px'
                title={currentUser?.authority === '2' ? intl.formatMessage({ id: "USER.GENERAL.MYTOTAL.AMOUNT" }) : 'Toplam Gelir'}
                percentile={currentUser?.authority === '2' ? '70' : ''}
                quantity={currentUser?.authority === '2' ? `${parseFloat(mainValues?.total_pay.total_donation ?? "0")}` : `${parseFloat(mainAdminValues?.total_pay.total_donation ?? "0")}`}
              />
            </div>
            {/*end::Col*/}

            {/*begin::Col*/}
            <div className='col-xl-12'>
              <MixedWidget14
                className='card-xl-stretch mb-xl-10'
                backGroundColor='#00BBA8'
                chartHeight='100px'
                title={currentUser?.authority === '2' ? intl.formatMessage({ id: "USER.GENERAL.MYTOTAL.PIECE" }) : 'Toplam Adet'}
                percentile={currentUser?.authority === '2' ? '10' : ''}
                quantity={currentUser?.authority === '2' ? `${mainValues?.total_pay.total_amount ?? ' '}`
                  : `${mainAdminValues?.total_pay.total_amount ?? ' '}`}
              />
            </div>
            {/*end::Col*/}
            {currentUser?.authority === '1' ?
              <div className='col-xl-12'>
                <MixedWidget14
                  className='card-xl-stretch mb-xl-10'
                  backGroundColor='#B6A999'
                  chartHeight='100px'
                  title={'Toplam Üye'}
                  percentile={''}
                  quantity={`${mainAdminValues?.total_pay.total_users ?? ' '}`}
                />
              </div>
              : <></>}
          </div>
        </div>
        {/*begin::Col*/}


        {/*end::Col*/}
      </div>
      {/*end::Row*/}



    </>
  )
}

const DashboardWrapper = () => {
  const { currentUser, logout } = useAuth()
  return (
    <>
      {currentUser?.authority === '2' ? <>
        <PageTitle breadcrumbs={[]}>
          Merhaba{' ' + currentUser?.adi + ' ' + currentUser?.soyadi}
        </PageTitle>
        <DashboardPage />
      </> :
        <EnableSidebar>
          <PageTitle breadcrumbs={[]}>
            Merhaba{' ' + currentUser?.adi + ' ' + currentUser?.soyadi}
          </PageTitle>
          <DashboardPage />
        </EnableSidebar>
      }

    </>
  )
}

export { DashboardWrapper }
