import axios, { AxiosResponse } from 'axios'
// import {AuthModel, UserModel} from './_models'

const API_URL = process.env.REACT_APP_DATABASE_API_URL

//export const GET_USER_BY_ACCESSTOKEN_URL = `${API_URL}/verify_token`
export const CATEGORY_URL = `${API_URL}categories`
export const DONATE_URL = `${API_URL}donates`
export const PAY_DONATE_URL = `${API_URL}pay_donate`
export const CREDIT_PAYMENT = `${API_URL}creditcard`
export const REGISTER_URL = `${API_URL}register`
export const REQUEST_PASSWORD_URL = `${API_URL}forgot_password`
export const STUDENT_URL = `${API_URL}students`

// Server should return AuthModel
const getCategories = () => {
  return axios.post<any>(`${CATEGORY_URL}/get_categories/`)
}

const getCurrencyList = () => {
  return axios.post<any>(`${DONATE_URL}/getCurrencyList/`)
}

const getLanguagesList = () => {
  return axios.post<any>(`${API_URL}getLanguages`).then((d: AxiosResponse<any>) => d.data)
}

const setUserValues = (vals: any, cookiebox: any) => {

  const all = axios.post<any>(`${PAY_DONATE_URL}/pay_user_info`, { data: vals, donatecards: cookiebox })

  return all
}

const credit_payment_first = (vals: any) => {
  const all = axios.post<any>(`${CREDIT_PAYMENT}/first`, vals)

  return all
}

const getProjectInfo = (vals: any) => {
  const all = axios.post<any>(`${DONATE_URL}/getProjectInfo`, vals).then((d: AxiosResponse<any>) => d.data)

  return all
}

const getContents = (contentId: any) => {
  return axios
    .post<any>(`${DONATE_URL}/get_content/${contentId}`)
    .then((d: AxiosResponse<any>) => d.data)
}

const getCards = (id: Number) => {
  const all = axios
    .post<any>(`${DONATE_URL}/get_category_donate/${id}`)
    .then((d: AxiosResponse<any>) => d.data)
  return all
}
const categories = () => {
  const all = axios.post(`${CATEGORY_URL}/get_categories`).then((d) => d.data)
  return all
}
const basketValues = (name: any, number: any) => {
  const all = axios.post(`${REGISTER_URL}/register_basket`).then((d) => d.data)
  return all
}

const getRecommendedDonates = (): Promise<any> => {
  const all = axios
    .post<any>(`${DONATE_URL}/get_recommended_donate`)
    .then((d: AxiosResponse<any>) => d.data)
  return all
}

const getStudentCardInfo = (id: any): Promise<any> => {
  const all = axios
    .post<any>(`${STUDENT_URL}/get_student_card/${id}`)
    .then((d: AxiosResponse<any>) => d.data)
  return all
}

const getDonateCardInfo = (id: number): Promise<any> => {
  const all = axios
    .post<any>(`${DONATE_URL}/getDonateCardInfo/${id}`)
    .then((d: AxiosResponse<any>) => d.data)
  return all
}

export { getCategories, getCards, getContents, basketValues, setUserValues, credit_payment_first, getProjectInfo, getRecommendedDonates, getStudentCardInfo, getCurrencyList, getLanguagesList, getDonateCardInfo }
// Server should return object => { result: boolean } (Is Email in DB)
