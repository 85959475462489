import axios, { AxiosResponse } from 'axios'
import { ID, Response } from '../../../../../../_metronic/helpers'
import { Categories, UsersQueryResponse } from './_models'

const API_URL = process.env.REACT_APP_DATABASE_API_URL
const TRACKING_URL = `${API_URL}/customer`
const GET_USERS_URL = `${API_URL}/donations/donations_list`

const getCustomerTracking = (query: string): Promise<UsersQueryResponse> => {
  const all = axios
    .get(`${TRACKING_URL}/get_customer_tracking/?${query}`)
    .then((d: AxiosResponse<UsersQueryResponse>) => d.data)
  return all
}

const getCustomerDetail = (id: Number) => {
  const all = axios
    .post<any>(`${TRACKING_URL}/get_customer_detail/${id}`)
    .then((response: Response<any>) => response.data)
  return all
}

const getCustomerMessages = (id: Number) => {
  const all = axios
    .post<any>(`${TRACKING_URL}/get_customer_messages/${id}`)
    .then((response: Response<any>) => response.data)
  return all
}

const getCustomerAutoInfo = (id: Number) => {
  const all = axios
    .post<any>(`${TRACKING_URL}/get_customer_auto/${id}`)
    .then((response: Response<any>) => response.data)
  return all
}
const getCustomerDonateMedia = (id: Number) => {
  const all = axios
    .post<any>(`${TRACKING_URL}/get_customer_media/${id}`)
    .then((response: Response<any>) => response.data)
  return all
}
const getCallCenterAuth = () => {
  const all = axios
    .post<any>(`${TRACKING_URL}/get_call_center_author`)
    .then((response: Response<any>) => response.data)
  return all
}
const getCategorieById = (id: ID): Promise<Categories | undefined> => {
  const all = axios
    .get(`${TRACKING_URL}/single_categorie/${id}`)
    .then((response: Response<Categories>) => response.data)
  return all
}

const createTask = (taskValues: Categories): Promise<Categories | undefined> => {
  return axios
    .post(`${TRACKING_URL}/create_task`, taskValues)
    .then((response: Response<any>) => response.data)
}
const addAuthorDB = (author: any): Promise<Object | undefined> => {
  const all = axios
    .post(`${TRACKING_URL}/add_author`, author)
    .then((response: Response<any>) => response.data)
  return all;
}
const addTaskDB = (task: any): Promise<Categories | undefined> => {
  return axios
    .post(`${TRACKING_URL}/add_author/`, task)
    .then((response: AxiosResponse<Response<Categories>>) => response.data)
    .then((response: Response<Categories>) => response.data)
}

const updateCategorie = (user: Categories): Promise<Categories | undefined> => {
  return (
    axios
      .post(`${TRACKING_URL}/update_categorie`, user)
      .then((response: Response<Categories>) => response.data)
  )
}

const deleteCategorie = (id: ID): Promise<Categories | undefined> => {
  const all = axios
    .post(`${TRACKING_URL}/delete_categorie/${id}`)
    .then((response: Response<Categories>) => response.data)

  return all
}
const autoCloseDB = (res: any): Promise<Categories | undefined> => {
  const all = axios
    .post(`${API_URL}/oto_odeme_iptal_et/`, res)
    .then((response: Response<Categories>) => response.data)

  return all
}

const deleteSelectedUsers = (userIds: Array<ID>): Promise<void> => {
  const requests = userIds.map((id) => axios.delete(`${TRACKING_URL}/${id}`))
  return axios.all(requests).then(() => { })
}

const addCallUserDb = async (callInfo: Object): Promise<Object | undefined> => {
  const response = await axios
    .post(`${TRACKING_URL}/addCallUserDb`, callInfo)
  return response.data
}

const addCustomerMessage = (author: any): Promise<Object | undefined> => {
  const all = axios
    .post(`${TRACKING_URL}/add_customer_message`, author)
    .then((response: Response<any>) => response.data)
  return all;
}

const callUserDB = async (id: string): Promise<Object | undefined> => {
  const response = await axios
    .post(`${TRACKING_URL}/callUserDB/${id}`)
    .then((response: Response<any>) => response.data)
  return response
}

const downloadUserCVC = (filter:object): Promise<void> => {
  const all = axios
    .post(`${TRACKING_URL}/downloadUserCVC`,filter)
    .then((response: Response<any>) => response.data)
  return all
}

export {
  getCustomerTracking,
  addAuthorDB,
  addTaskDB,
  autoCloseDB,
  getCallCenterAuth,
  getCustomerDonateMedia,
  deleteCategorie,
  deleteSelectedUsers,
  getCategorieById,
  createTask,
  updateCategorie,
  getCustomerDetail,
  getCustomerAutoInfo,
  addCallUserDb,
  getCustomerMessages,
  addCustomerMessage,
  callUserDB,
  downloadUserCVC
}
