import React, { useState, useRef, useEffect } from 'react'
import ProgressBar from 'react-bootstrap/ProgressBar'
import { Link } from 'react-router-dom'
import Swal from 'sweetalert2'
import { object } from 'yup'
import { CookieBoxModel, useAuth } from '../../../auth'
import Type1 from './payTypes/Type1'
import './style.css'
import '../../styles/button.scss'
import { useIntl } from 'react-intl'
import { KTSVG } from '../../../../../_metronic/helpers'
const Card = ({ content, url }: any) => {
  const intl = useIntl();
  const { auth, cookieBox, saveCookieBox, genericValues, saveGenericValues } = useAuth()
  const [langData, setLangData] = useState<any>(genericValues?.langJson)
  useEffect(() => {
    setLangData(genericValues?.langJson)
  }, [genericValues])


  const handleOnclickDonate = async (val: any) => {
    var inputQuantity = (document.getElementById('donate_input_' + val.card_id) as HTMLInputElement)
      .value
    let inputPiece = 0
    var inputPieceHtml = document.getElementById(
      'donate_input_piece_' + val.card_id
    ) as HTMLInputElement
    if (inputPieceHtml) {
      inputPiece = Number(inputPieceHtml.value)
    }
    if (inputPiece < 1) {
      inputPiece = 1
    }

    let inputMonth = 0
    var inputMonthHtml = document.getElementById(
      'donate_input_month_' + val.card_id
    ) as HTMLInputElement
    if (inputMonthHtml) {
      inputMonth = Number(inputMonthHtml.value)
    }
    if (Number(inputQuantity) > 0) {
      val.quantity = Number(inputQuantity)
      val.currentTotal = inputPiece
      const addArray: {
        cardOrder: any
        piece: number
        other: boolean
        name: string
        phone: string
        autopay: boolean
        month: number
        day: number
        error: boolean
      }[] = []
      if (val.info_type === 1) {
        addArray.push({
          cardOrder: (val.values ?? []).length + 1,
          piece: inputPiece,
          other: false,
          name: '',
          phone: '',
          autopay: false,
          month: 0,
          day: 0,
          error: false
        })
      } else if (val.info_type === 2) {
        for (let ix = 0; ix < inputPiece; ix++) {
          addArray.push({
            cardOrder: (val.values ?? []).length + ix + 1,
            piece: 1,
            other: false,
            name: '',
            phone: '',
            autopay: false,
            month: 0,
            day: 0,
            error: false
          })
        }
      } else if (val.info_type === 3) {

        addArray.push({
          cardOrder: (val.values ?? []).length + 1,
          piece: inputPiece,
          other: false,
          name: '',
          phone: '',
          autopay: false,
          month: 0,
          day: 0,
          error: false
        })
      }
      let pos = (cookieBox?.obje ?? [])
        .map((e: any) => e.basket_id)
        .indexOf(val.card_id + '-' + val.quantity)
      if (val.pay_type == 6 || val.pay_type == 5 ) {
        pos = -1;
      }
      if (pos === -1) {
        if (val.pay_type === 4) {
          let tot = (cookieBox?.obje ?? []).filter(x => x.card_id === val.card_id).map(x => Number(x.quantity) * Number(x.total)).reduce((acc, val) => acc + val, 0)
          if ((Number(val.amount) + Number(inputQuantity) + Number(tot)) > Number(val.target)) {
            Swal.fire({
              title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_1" }),
              text: intl.formatMessage({ id: "MAIN.CARD.WARNING_1" }),
              icon: 'warning',
              showConfirmButton: false,
              timer: 2500,
            })
            return false;
          }
        } else if (val.pay_type === 5) {
          let tot = (cookieBox?.obje ?? []).filter(x => x.card_id === val.card_id).map(x => Number(x.total)).reduce((acc, val) => acc + val, 0)
          if ((Number(val.count) + Number(inputPiece) + Number(tot)) > Number(val.target)) {
            Swal.fire({
              title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_1" }),
              text: intl.formatMessage({ id: "MAIN.CARD.WARNING_1" }),
              icon: 'warning',
              showConfirmButton: false,
              timer: 2500,
            })
            return false;
          }
        }
        if (val.pay_type == 6 || val.pay_type == 5 ) {
          val.basket_id = val.card_id + '-' + val.quantity + "-" + ((cookieBox?.obje ?? []).length + 1)
        } else {
          val.basket_id = val.card_id + '-' + val.quantity
        }
        val.total = inputPiece
        val.monthly = inputMonth

        val.values = addArray
        if (cookieBox?.obje === undefined) {
          saveCookieBox({ ...{ obje: [{ ...val }] } } as CookieBoxModel)
        } else {
          saveCookieBox({ ...{ obje: [...(cookieBox?.obje ?? []), { ...val }] } } as CookieBoxModel)
        }
      } else {
        if (val.pay_type === 4) {
          let tot = (cookieBox?.obje ?? []).filter(x => x.card_id === val.card_id).map(x => Number(x.quantity) * Number(x.total)).reduce((acc, val) => acc + val, 0)
          if ((Number(val.amount) + Number(inputQuantity) + Number(tot)) > Number(val.target)) {
            Swal.fire({
              title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_1" }),
              text: intl.formatMessage({ id: "MAIN.CARD.WARNING_1" }),
              icon: 'warning',
              showConfirmButton: false,
              timer: 2500,
            })
            return false;
          }
        } else if (val.pay_type === 5) {
          let tot = (cookieBox?.obje ?? []).filter(x => x.card_id === val.card_id).map(x => Number(x.total)).reduce((acc, val) => acc + val, 0)
          if ((Number(val.count) + Number(inputPiece) + Number(tot)) > Number(val.target)) {
            Swal.fire({
              title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_1" }),
              text: intl.formatMessage({ id: "MAIN.CARD.WARNING_1" }),
              icon: 'warning',
              showConfirmButton: false,
              timer: 2500,
            })
            return false;
          }
        }
        saveCookieBox({
          ...{
            obje: (cookieBox?.obje ?? []).map((obj: any) => {
              // 👇️ if id equals 2, update country property
              if (obj.basket_id === val.card_id + '-' + val.quantity) {

                return {
                  ...obj,
                  total: obj.total + inputPiece,
                  values: obj.values.concat(addArray),
                }
              }

              // 👇️ otherwise return object as is
              return obj
            }),
          },
        } as CookieBoxModel)
      }
      Swal.fire({
        title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_2" }),
        text: intl.formatMessage({ id: "MAIN.CARD.WARNING_2" }),
        icon: 'success',
        showConfirmButton: false,
        timer: 1500,
      })
    } else {
      Swal.fire({
        title: intl.formatMessage({ id: "MAIN.CARD.WARNING_HEAD_3" }),
        text: intl.formatMessage({ id: "MAIN.CARD.WARNING_3" }),
        icon: 'info',
        showConfirmButton: false,
        timer: 1500,
      })
    }
  }
  const limit = (string = '', limit = 0) => {
    return string.substring(0, limit)
  }
  return (
    <>
      {content.map((val: any, index: any) => {
        return (
          <div key={index} className='col-xs-12 col-sm-6 col-md-6 col-lg-4 mt-5 mb-3'>
            <div
              className='card'
              style={{ borderRadius: '0px 15px 15px 15px ', backgroundColor: 'white', boxShadow: '0 0 20px rgba(0, 0, 0, 0.2) ' }}
            >
              <div className=' w-100'>
                {/* {`${url}/${val.id}`} */}
                <a href={`${val.content_link === null || undefined ? `#` : `${val.content_link}  `}`}>
                  <div className='contentss-overlay w-100'></div>

                  <img
                    style={{ borderRadius: ' 0px 15px 0px 0px', height: '190px', objectFit: 'cover' }}
                    className='contentss-image top   w-100'
                    loading='lazy'
                    src={`${process.env.REACT_APP_MEDIA_API_URL}${val.icon}`}
                  />
                </a>
                {val.pay_type === 4 ? (<>
                  <span
                    data-bs-toggle="modal"
                    data-bs-target="#kt_modal_1x"
                    //buraya tıklanma özelliği kapatılıyor
                    style={{ position: 'absolute', top: 157, left: 3, maxWidth: '220px', borderRadius: '5px 5px 0px 0px', cursor: 'pointer', backgroundColor: '#f1f1f1' }} className="d-flex   badge    ">
                    <div className=" text-dark h5  ">
                      <KTSVG path="/media/icons/duotune/general/gen018.svg" className=" svg-icon-1hx svg-icon-warning " />
                      <b style={{ color: '#0e7154' }}>{val.card_text !== '' ? limit(langData["DB.DONATE_LIST.TEXT_" + val.card_id], 12) : ''}</b></div>
                  </span>
                  <div className="modal fade" tabIndex={-1} id="kt_modal_1">
                    <div className="modal-dialog">
                      <div className="modal-content">
                        <div className="modal-header">
                          <h5 className="modal-title">{intl.formatMessage({ id: "MAIN.GENERAL.MAP" })}</h5>
                          <div
                            className="btn btn-icon btn-sm btn-active-light-primary ms-2"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                          >
                            <KTSVG
                              path="/media/icons/duotune/arrows/arr061.svg"
                              className="svg-icon svg-icon-2x"
                            />
                          </div>
                        </div>
                        <div className="modal-body">
                          <div className='w-50 h-50' dangerouslySetInnerHTML={{ __html: val.card_map }} />
                        </div>
                        <div className="modal-footer">
                          <button
                            type="button"
                            className="btn btn-light"
                            data-bs-dismiss="modal"
                          >
                            {intl.formatMessage({ id: "MAIN.GENERAL.CLOSE" })}
                          </button>

                        </div>
                      </div>
                    </div>
                  </div>
                </>) : (<></>)}

                {/* <div className='contentss-details fadeIn-top'>
                    <h3 className='contentss-title'>Bağış Detayları</h3>
                    <p className='contentss-text'>Bağış hakkında içerik </p>
                  </div> */}

              </div>
              {/* <div className="content ">
                            <div className="content-overlay "></div>
                            <img className='top img-fluid  w-100  content-image ' style={{ width: '100%', maxHeight: '150px', }} src={`${process.env.REACT_APP_MEDIA_API_URL}${val.icon}`} />
                            <div className="content-details fadeIn-top">
                                <h3>This is a title</h3>
                                <p>This is a short description</p>
                            </div>

                        </div> */}
              <div className='p-5'>
                <a href={`${val.content_link === null || undefined ? `#` : `${val.content_link}  `}`}>
                  <span style={{ color: '#000' }} className='card-title mt-5    h1'>
                    {langData["DB.DONATE_LIST.VALUE_" + val.card_id]}
                  </span>
                </a>

                <p style={{ fontSize: '1.2rem' }} className='card-text mt-2'>{val.card_text !== '' ? langData["DB.DONATE_LIST.TEXT_" + val.card_id] : ''}</p>
                {<Type1 val={val} />}

                <div className='d-grid py-3  gap-2 mt-auto align-items-center'>
                  <button
                    onClick={() => handleOnclickDonate(val)}
                    style={{ borderRadius: ' 0px 15px 0px 15px', backgroundColor: '#0E7154', color: '#fff', zIndex: '10' }}
                    className=' btn-new btn-hov text-center  d-inline-block sticky-bottom fw-bold '
                  >
                    {/* <button
                    style={{borderRadius: '0px 15px 0px 15px ',fontSize:'18px',color:'#fff',backgroundColor:'#0E7154'}}
                    onClick={() => handleOnclickDonate(val)}
                    className='btn  d-inline-block fw-bolder '
                  > */}
                    {intl.formatMessage({ id: "MAIN.CARD.BUTTON" })}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )
      })}
    </>
  )
}

export default Card
