import { useState } from 'react';
import { Dropdown, Offcanvas } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Form from 'react-bootstrap/Form';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { Navigate, Link, NavLink, useHref } from 'react-router-dom';
import { toAbsoluteUrl } from '../../../../../_metronic/helpers';
import { useAuth } from '../../../auth';
import '../../styles/Header.css'
//import { toAbsoluteUrl } from './../../../../../_metronic/helpers/AssetHelpers';

function Header() {
  const { currentUser, logout } = useAuth()
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  return (
    <>
      <Navbar style={{ background: 'rgba(255,255,255,0.3)', backdropFilter: 'blur(7px)' }} className='sticky-top' bg="soft" expand="lg">
        <Container>
          <Navbar.Brand href="/"><img style={{ width: '200px', height: '50px', }} src={toAbsoluteUrl('/media/logos/bereket-logo.png')} />
          </Navbar.Brand>
          <Navbar.Toggle/>
          <Navbar.Offcanvas
            onHide={handleClose}
            className=" text-white "
            style={{ backgroundColor: '#0e7154', width: '250px' }}
            id={`offcanvasNavbar-expand`}
            aria-labelledby={`offcanvasNavbarLabel-expand`}
          >
            <Offcanvas.Header closeButton>
              <Offcanvas.Title id={`offcanvasNavbarLabel-expand`}>
              </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body className='mt-5 pe-0'  >
              <Nav className=" justify-content-end flex-grow-1 pe-3 h5 colorsforheader">
                <Nav.Link href='https://www.bereket.org.tr/ ' >ANA SAYFA</Nav.Link>
                <NavDropdown

                  title="KURUMSAL"
                  id={`offcanvasNavbarDropdown-expand`}
                >
                  <NavDropdown.Item className='h5 fw-bold ' href="https://www.bereket.org.tr/hakkimizda/">Hakkımızda</NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/degerlerimiz/">
                    Değerlerimiz
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/insan-kaynaklari/">
                    İnsan Kaynakları
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/sss/">
                    Sık Sorulan Sorular (SSS)
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                </NavDropdown>
                <NavDropdown
                  title="GÖNÜLLÜ"
                  id={`offcanvasNavbarDropdown-expand`}
                >
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/gonullu-kimdir/">Gönüllü Kimdir ?</NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/gonullu-olarak-ne-yapabilirim/">
                    Gönüllü olarak ne yapabilirim ?
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/calisma-alanlari/">
                    Çalışma Alanları
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/gonullu-formu/">
                    Gönüllü Formu
                  </NavDropdown.Item>
                  <NavDropdown.Divider />

                </NavDropdown>
                <NavDropdown
                  title="PROJELERİMİZ"
                  id={`offcanvasNavbarDropdown-expand`}
                >
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/yetim/">Yetim</NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/kurban-bagisi/">
                    Kurban Bağışı
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/su-kuyusu/">
                    Su Kuyusu
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/iftar-bagisi/">
                    İftar Bağışı
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/hafiz-giydir/">
                    Hafız Giydir
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/hafiz-yetistiriyorum/">
                    Hafız Yetiştiriyorum
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/hafize-yetistiriyorum/">
                    Hafize Yetiştiriyorum
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/dugun-yemegi-bagisi/">
                    Düğün Yemeği Bağışı
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                </NavDropdown>
                <NavDropdown
                  title="BAĞIŞ YAP"
                  id={`offcanvasNavbarDropdown-expand`}
                >
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/bagis/">Online Bağış</NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/hesap-numaralarimiz/">
                    Hesap Numaraları
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/mobil-aidat/">
                    Mobil Aidat
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/kumbara-istiyorum/">
                    Kumbara İstiyorum
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="https://www.bereket.org.tr/kumbara-istiyorum/">
                    Zekat Hesaplama
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="http://www.bereket.org.tr/zekat/ ">
                    Zekat
                  </NavDropdown.Item>
                  <NavDropdown.Item className='h5 fw-bold' href="http://www.bereket.org.tr/ramazan">
                    Ramazan
                  </NavDropdown.Item>
                  <NavDropdown.Divider />
                </NavDropdown>
                <Nav.Link href="https://www.bereket.org.tr/online-medrese/">ONLİNE MEDRESE</Nav.Link>
                <Nav.Link href="https://www.bereket.org.tr/iletisim/">İLETİŞİM</Nav.Link>


                <Dropdown >
                  <Dropdown.Toggle style={{ color: '#cab47b' }} variant="" id="dropdown-basic">
                    <i style={{ color: '#cab47b' }} className='m-1 fa-sharp fa-solid fa-user-tie fs-2x'   ></i>
                    {currentUser ? `${currentUser.adi + ' ' + currentUser.soyadi}` : (<span className="fw-bolder"> Giriş Yap </span>)}

                  </Dropdown.Toggle>


                  {currentUser ?
                    <>
                      <Dropdown.Menu className='dropdown-menu'>
                        <Dropdown.Item className='p-3 text-center' href={`${process.env.REACT_APP_ANASAYFA}auth`}><b>{`HESABIM`}</b></Dropdown.Item>
                        <div className="d-grid gap-2">
                          <button className='btn btn-danger d-block btn-sm' onClick={() => logout()}>Çıkış Yap</button>
                        </div>
                      </Dropdown.Menu>
                    </>
                    :
                    <>
                      <Dropdown.Menu className='dropdown-menu'>
                        {currentUser ? <><Dropdown.Item href={`${process.env.REACT_APP_ANASAYFA}auth`}>{`HESABIM`}</Dropdown.Item></> : <>
                          <Dropdown.Item href={`${process.env.REACT_APP_ANASAYFA}auth`}> Giriş Yap</Dropdown.Item>
                          <Dropdown.Item href={`${process.env.REACT_APP_ANASAYFA}registration`}>Üye Ol</Dropdown.Item></>}


                      </Dropdown.Menu>
                    </>}

                </Dropdown>





              </Nav>
            </Offcanvas.Body>

          </Navbar.Offcanvas>


        </Container>
      </Navbar>
      <div className="separator border-3 my-2"></div>
    </>
  );
}

export default Header;