/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from 'react'
import { getUserDonations } from '../../../../app/modules/apps/user-donation-management/donations-list/core/_requests'
import { KTSVG, stringifyRequestQuery, toAbsoluteUrl } from '../../../helpers'
import { useEffect } from 'react';
import { getUserMainInfo } from '../../../../app/pages/dashboard/_requests';

type Props = {
  className: string,
  title: string
}

const TablesWidget9: React.FC<Props> = ({ className, title }) => {
  const [mainValues, setMainValues] = useState<any>();
  const UserInfo = async () => {
    const deger = await getUserMainInfo()
    setMainValues(deger.all_donates);
  }
  useEffect(() => {
    UserInfo()
  }, [])

  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>{title}</span>
          {/* <span className='text-muted mt-1 fw-semibold fs-7'>Over 500 members</span> */}
        </h3>
        <div
          className='card-toolbar'
          data-bs-toggle='tooltip'
          data-bs-placement='top'
          data-bs-trigger='hover'
          title='Click to add a user'
        >
          {/* <a
            href='#'
            className='btn btn-sm btn-light-primary'
            data-bs-toggle='modal'
            data-bs-target='#kt_modal_invite_friends'
          >
            <KTSVG path='/media/icons/duotune/arrows/arr075.svg' className='svg-icon-3' />
            New Member
          </a> */}
        </div>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        {/* begin::Table container */}
        <div className='table-responsive'>
          {/* begin::Table */}
          <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
            {/* begin::Table head */}
            <thead>
              <tr className='fw-bold text-muted'>

                <th className='min-w-50px'>Bağış No</th>
                <th className='min-w-100px'>Bağış Adı</th>
                <th className='min-w-100px'>Adet</th>
                <th className='min-w-100px'>Tutar</th>
                <th className='min-w-100px'>Toplam Tutar</th>
                <th className='min-w-100px'>Döviz</th>
                <th className='min-w-100px'>Bağış Tarihi</th>
                <th className='min-w-100px'>Ödeme Şekli</th>
                <th className='min-w-100px'>Durum</th>
                {/* <th className='min-w-100px text-end'>Actions</th> */}
              </tr>
            </thead>
            {/* end::Table head */}
            {/* begin::Table body */}

            <tbody>
              {mainValues?.map((vals: any, i: number) => {
                return (
                  <>
                    <tr key={i} className='align-items-center justify-content-center'>
                      {/* bağış no */}
                      <td>
                        <span className='text-dark text-center fw-bold text-hover-primary d-block fs-6'>
                          {vals?.id}
                        </span>
                      </td>

                      {/* bağış adı */}
                      <td>
                        <span className='text-dark fw-bold text-hover-primary d-block fs-6'>
                          {vals?.name}
                        </span>

                      </td>
                      {/* bağış adet */}
                      <td className='text-start'>
                        <span className='text-dark text-start fw-bold text-hover-primary d-block fs-6'>
                          {vals?.total}
                        </span>
                      </td>
                      {/* bağış tutar */}
                      <td className='text-start'>
                        <span className='text-dark text-start fw-bold text-hover-primary d-block fs-6'>
                          {vals?.quantity}   ₺

                        </span>
                      </td>
                      {/* bağış toplam tutar */}
                      <td className='text-center'>
                        <span className='text-dark  fw-bold text-hover-primary d-block me-10'>
                          {vals?.total_amount}  ₺
                        </span>
                      </td>
                      {/* bağış Kimin adına */}
                      <td className='text-center'>
                        <span className='text-dark text-start fw-bold text-hover-primary d-block fs-6'>
                          {vals?.currency}


                        </span>
                      </td>
                      {/* bağış Bağış Tarihi */}
                      <td className='text-center'>
                        <span className='text-dark text-start fw-bold text-hover-primary d-block fs-6'>
                          {vals?.cr_date}
                        </span>
                      </td>
                      {/* bağış Ödeme şekli */}
                      <td className='text-center'>
                        <span className='text-dark text-start fw-bold text-hover-primary d-block fs-6'>
                          {vals?.pay_type === 0 && 'Tanımsız'}
                          {vals?.pay_type === 1 && 'Kredi Kartı'}
                          {vals?.pay_type === 2 && 'Havale'}
                        </span>
                      </td>
                      {/* bağış Durum */}
                      <td className='text-center'>
                        <span className='text-dark text-start fw-bold text-hover-primary d-block fs-6'>
                          {vals?.status === 0 && 'Onay Bekliyor'}
                          {vals?.status === 1 && 'Onaylandı'}
                          {vals?.status === 2 && 'Reddedildi'}
                          {vals?.status === 3 && 'Hata'}
                        </span>
                      </td>
                      {/* işlemler */}
                      {/* <td>
                        <div className='d-flex justify-content-end flex-shrink-0'>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen019.svg'
                              className='svg-icon-3'
                            />
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-1'
                          >
                            <KTSVG path='/media/icons/duotune/art/art005.svg' className='svg-icon-3' />
                          </a>
                          <a
                            href='#'
                            className='btn btn-icon btn-bg-light btn-active-color-primary btn-sm'
                          >
                            <KTSVG
                              path='/media/icons/duotune/general/gen027.svg'
                              className='svg-icon-3'
                            />
                          </a>
                        </div>
                      </td> */}
                    </tr>
                  </>)
              })}



            </tbody>
            {/* end::Table body */}
          </table>
          {/* end::Table */}
        </div>
        {/* end::Table container */}
      </div>
      {/* begin::Body */}
    </div>
  )
}

export { TablesWidget9 }
